import React, { useEffect, useState, createRef } from "react";
import styled from "styled-components";
import BaseSelect from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import Recaptcha from "react-google-recaptcha";
import { Button } from "../../../../components";
import { useWindowSize } from "../../../../hooks";
import FixRequiredSelect from "../FixRequiredSelect";
import customDropdownMenuStyles from "../../../../styles/customDropdownMenuStyles";

const FormSelect = (props) => {
  return (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );
};

const resetFormValues = () => ({
  name: "",
  email: "",
  number: "",
  jobTitle: "",
  organisation: "",
  otherSampleType: "",
  sampleContainer: "",
  numberOfSamples: "",
  additionalInformation: "",
  mailingListConsent: false,
  privacyPolicyConsent: false,
  botField: "",
});

const RequestQuoteForm = ({ closeContactFormDialog }) => {
  const [formValues, setFormValues] = useState(resetFormValues());
  const [services, setServices] = useState([]);
  const [sampleType, setSampleType] = useState("");
  const [prompt, setPrompt] = useState(null);
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const recaptchaRef = createRef();

  const { windowWidth } = useWindowSize();
  const encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  const sendFormToNetlify = async (e) => {
    setIsMessageSending(true);

    const form = e.target;

    const recaptchaValue = recaptchaRef.current.getValue();

    const chosenSampleType = sampleType.value === 'Other' ? formValues.otherSampleType : sampleType.value;

    const options = {
      method: "POST",
      body: encodeData({
        "form-name": form.getAttribute("name"),
        name: formValues.name,
        email: formValues.email,
        number: formValues.number,
        jobTitle: formValues.jobTitle,
        organisation: formValues.organisation,
        servicesInterestedIn: services.join(", "),
        sampleType: chosenSampleType,
        numberOfSamples: formValues.numberOfSamples,
        sampleContainer: formValues.sampleContainer,
        additionalInformation: formValues.additionalInformation,
        contactConsent: formValues.contactConsent,
        mailingListConsent: formValues.mailingListConsent,
        privacyPolicyConsent: formValues.privacyPolicyConsent,
      }),
    };

    return fetch("/", options)
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  const serviceCheckboxOptions = [
    { name: "DNA Extractions", value: "DNA Extractions" },
    { name: "RNA Extractions", value: "RNA Extractions" },
    { name: "Genotyping", value: "Genotyping" },
    { name: "Methylation Profiling", value: "Methylation Profiling" },
    { name: "Whole Genome Sequencing", value: "Whole Genome Sequencing" },
    { name: "Whole Exome Sequencing", value: "Whole Exome Sequencing" },
    { name: "Sanger Sequencing", value: "Sanger Sequencing" },
    { name: "Total RNA Sequencing", value: "Total RNA Sequencing" },
    {
      name: "Single Cell Immune Profiling",
      value: "Single Cell Immune Profiling",
    },
    {
      name: "Single Nuclei Gene Expression",
      value: "Single Nuclei Gene Expression",
    },
    {
      name: "Single Cell Gene Expression",
      value: "Single Cell Gene Expression",
    },
    {
      name: "Biobanking and Sample Management",
      value: "Biobanking and Sample Management",
    },
  ];

  const sampleTypes = [
    { label: "Whole blood", value: "Whole blood" },
    { label: "Saliva", value: "Saliva" },
    { label: "Buffy Coat", value: "Buffy Coat" },
    { label: "FFPE Tissue", value: "FFPE Tissue" },
    { label: "Snap Frozen Tissue", value: "Snap Frozen Tissue" },
    { label: "Bone Marrow Aspirate", value: "Bone Marrow Aspirate" },
    { label: "Whole blood (PAXgene)", value: "Whole blood (PAXgene)" },
    { label: "DNA", value: "DNA" },
    { label: "FFPE DNA", value: "FFPE DNA" },
    { label: "RNA", value: "RNA" },
    { label: "Other", value: "Other" },
  ];

  const resetForm = () => {
    setFormValues(resetFormValues());
    setServices([]);
    setSampleType("");
    setIsMessageSending(false);
    setIsMessageSent(true);
    setPrompt(null);
    recaptchaRef.current?.execute();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (formValues.name.length < 1) {
      document.getElementById("name").focus();
      return;
    }

    if (formValues.email.length < 1) {
      document.getElementById("email").focus();
      return;
    }

    if (formValues.organisation.length < 1) {
      document.getElementById("organisation").focus();
      return;
    }

    if (formValues.services.length < 1) {
      document.getElementById("services").focus();
      setPrompt("Please choose the service(s) you are interested in");
      return;
    }

    if (!sampleType) {
      document.getElementById("sampleType")?.focus();
      return;
    }

    if (sampleType.value === 'Other') {
      if(!formValues.otherSampleType) {
        document.getElementById("otherSampleType").focus();
        return;
      }
    }

    if (!formValues.sampleNumber) {
      document.getElementById("sampleNumber").focus();
      return;
    }

    if (!formValues.contactConsent) {
      document.getElementById("contactConsent").focus();
      return;
    }

    if (!formValues.privacyPolicyConsent) {
      document.getElementById("privacyPolicyConsent").focus();
      return;
    }

    if (!recaptchaValue) {
      document.getElementById("rc-anchor-container")?.focus();
      return;
    }

    sendFormToNetlify(e).then(() =>
      setTimeout(() => {
        resetForm();
      }, 1750)
    );
  };

  const checkIfMessageAlreadySent = () => {
    if (isMessageSent) {
      setIsMessageSent(false);
    }
  };

  useEffect(() => {
    if (
      formValues.contactConsent &&
      formValues.privacyPolicyConsent &&
      recaptchaRef.current.getValue()
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValues.contactConsent, formValues.privacyPolicyConsent, recaptchaRef.current]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Form
      name="request-quote"
      id="request-quote"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <SectionTitle>Request a Quote</SectionTitle>
      <small>
        Fields marked with an <span>*</span> are mandatory
      </small>
      <SectionDescription>Requester Details</SectionDescription>
      <Input type="hidden" name="form-name" value="Level 1 Submission" />
      <p hidden>
        <Label>
          Don’t fill this out:
          <Input
            name="bot-field"
            value={formValues.botField}
            onChange={handleInputChange}
          />
        </Label>
      </p>
      <Details>
        <Label>
          <p>
            Full Name<span>*</span>
          </p>
          <Input
            type="name"
            name="name"
            id="name"
            placeholder="Enter Full Name"
            value={formValues.name}
            onChange={handleInputChange}
            required
          />
        </Label>
        <Label>
          <p>
            Email<span>*</span>
          </p>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter Email"
            value={formValues.email}
            onChange={handleInputChange}
            required
          />
        </Label>
        <Label>
          <p>Contact Number</p>
          <Input
            type="tel"
            name="number"
            id="number"
            placeholder="Enter Contact Number"
            value={formValues.number}
            onChange={handleInputChange}
          />
        </Label>
        <Label>
          <p>Job Title</p>
          <Input
            type="text"
            name="jobTitle"
            id="jobTitle"
            placeholder="Enter Job Title"
            value={formValues.jobTitle}
            onChange={handleInputChange}
          />
        </Label>
        <Label>
          <p>
            Organisation Name<span>*</span>
          </p>
          <Input
            type="text"
            name="organisation"
            id="organisation"
            placeholder="Enter Organisation Name"
            value={formValues.organisation}
            onChange={handleInputChange}
            required
          />
        </Label>
      </Details>
      <SectionDescription>Service Details</SectionDescription>
      <Label id="services">
        <p>
          What service(s) are you interested in?<span>*</span>
        </p>
        <AnimatePresence initial={false}>
          {prompt && (
            <motion.span
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.4,
                ease: [0.04, 0.62, 0.23, 0.98],
                opacity: { duration: 0.1 },
              }}
              style={{ fontSize: "0.75rem", fontWeight: "300" }}
            >
              {prompt}
            </motion.span>
          )}
        </AnimatePresence>
        <Services>
          {serviceCheckboxOptions.map((checkbox, index) => (
            <>
              <Label compact>
                <FormCheck
                  selected={services.includes(checkbox.value)}
                  key={index}
                >
                  <FormCheckbox
                    type="checkbox"
                    id="servicesInterestedIn"
                    name="servicesInterestedIn"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPrompt(null);
                        setServices((previousState) => [
                          ...previousState,
                          e.target.value,
                        ]);
                      } else {
                        const filteredState = services.filter(
                          (item) => item !== e.target.value
                        );
                        setServices(filteredState);
                      }
                    }}
                    checked={services.includes(checkbox.value)}
                    value={checkbox.value}
                  />
                  <p>{checkbox.name}</p>
                </FormCheck>
              </Label>
              {index === 10 && <div />}
            </>
          ))}
        </Services>
      </Label>
      <SectionDescription>Sample Details</SectionDescription>
      <SampleDetails>
        <Label id="sampleType">
          <p>
            Sample Type<span>*</span>
          </p>
          <FormSelect
            value={sampleType}
            onChange={setSampleType}
            id="sampleType"
            name="sampleType"
            placeholder="Choose your Sample Type"
            styles={customDropdownMenuStyles({ windowWidth })}
            options={sampleTypes}
          />
        </Label>
        <AnimatePresence initial={false}>
          {windowWidth < 1024 && sampleType?.value === "Other" && (
            <Label
              id="otherType"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.4,
                ease: [0.04, 0.62, 0.23, 0.98],
                opacity: { duration: 0.1, delay: 0.3 },
              }}
            >
              <p>
                Please provide other sample type<span>*</span>
              </p>
              <Input
                type="text"
                name="otherSampleType"
                id="otherSampleType"
                placeholder="Enter other sample type"
                value={formValues.otherSampleType}
                onChange={handleInputChange}
              />
            </Label>
          )}
        </AnimatePresence>
        <Label>
          <p>
            Number of Samples<span>*</span>
          </p>
          <Input
            type="number"
            name="numberOfSamples"
            id="numberOfSamples"
            placeholder="Enter Number"
            value={formValues.numberOfSamples}
            onChange={handleInputChange}
          />
        </Label>
        <Label>
          <p>Sample Container</p>
          <Input
            type="text"
            name="sampleContainer"
            id="sampleContainer"
            placeholder="Enter Container"
            value={formValues.sampleContainer}
            onChange={handleInputChange}
          />
        </Label>
      </SampleDetails>
      <AnimatePresence initial={false}>
          {windowWidth >= 1024 && sampleType?.value === "Other" && (
            <Label
              id="otherType"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 0.4,
                ease: [0.04, 0.62, 0.23, 0.98],
                opacity: { duration: 0.1, delay: 0.3 },
              }}
              style={{ maxWidth: '364px' }}
            >
              <p>
                Please provide other sample type<span>*</span>
              </p>
              <Input
                type="text"
                name="otherSampleType"
                id="otherSampleType"
                placeholder="Enter other sample type"
                value={formValues.otherSampleType}
                onChange={handleInputChange}
              />
            </Label>
          )}
        </AnimatePresence>
      <AdditionalInformation>
        <Label>
          <p>Additional Information</p>
          <TextArea
            name="additionalInformation"
            id="additionalInformation"
            placeholder="Enter Information"
            value={formValues.additionalInformation}
            rows="5"
            onChange={handleInputChange}
          />
        </Label>
        <Checkboxes>
          <Label>
            <FormCheck small>
              <FormCheckbox
                type="checkbox"
                id="contactConsent"
                name="contactConsent"
                onChange={handleInputChange}
                checked={formValues.contactConsent}
                value={formValues.contactConsent}
                required
              />
              <p>
                I consent to being contacted by Genseq regarding my inquiry.
                <span>*</span>
              </p>
            </FormCheck>
          </Label>
          <Label>
            <FormCheck small>
              <FormCheckbox
                type="checkbox"
                id="privacyPolicyConsent"
                name="privacyPolicyConsent"
                onChange={handleInputChange}
                checked={formValues.privacyPolicyConsent}
                value={formValues.privacyPolicyConsent}
              />
              <p>
                I understand that the information provided by me is subject to
                the Privacy Policy.<span>*</span> (
                <a href="/privacy-policy">See Privacy Policy</a>)
              </p>
            </FormCheck>
          </Label>
          <Label>
            <FormCheck small>
              <FormCheckbox
                type="checkbox"
                id="mailingListConsent"
                name="mailingListConsent"
                onChange={handleInputChange}
                checked={formValues.mailingListConsent}
                value={formValues.mailingListConsent}
              />
              <p>
                I would like join the Genseq mailing list to stay updated on the
                latest news and offers.
              </p>
            </FormCheck>
          </Label>
        </Checkboxes>
      </AdditionalInformation>
      <RecaptchaContainer>
        <Recaptcha
          ref={recaptchaRef}
          sitekey="6Lch700pAAAAALDw2GocqmC-3fbqhqIPeJY2lkEo"
          size="normal"
          id="recaptcha-google"
          onExpired={() => {
            setDisabled(true);
            recaptchaRef.current?.execute();
          }}
          onChange={() => {
            if (
              formValues.contactConsent &&
              formValues.privacyPolicyConsent &&
              recaptchaRef.current.getValue()
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        />
      </RecaptchaContainer>
      {isMessageSent ? (
        <Response id="request-form-success-message"
          className="request-form-success-message" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          Thank you, we'll be in touch soon.
        </Response>
      ) : (
        <Button
          type="submit"
          solid="var(--genseq-blue)"
          id="request-a-quote-submit"
          text={isMessageSending ? "Sending Request" : "Request a Quote"}
          maxWidth="220px"
          disabled={disabled}
        />
      )}
    </Form>
  );
};

const Form = styled.form`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.875rem 1.25rem;
  span {
    color: var(--genseq-orange);
  }
  button {
    align-self: flex-end;
    margin-top: 0.875rem;
  }
  @media screen and (min-width: 780px) {
    padding: 1.875rem;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: left;
  width: 100%;
`;

const SectionDescription = styled.h5`
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1.25rem;
  text-transform: uppercase;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
`;

const Label = styled(motion.label)`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 0.625rem;
  padding-top: ${({ compact }) => (compact ? "0" : "1.25rem")};
  &:focus {
    color: var(--genseq-orange);
  }
`;

const Services = styled.div`
  display: grid;
  gap: 1rem 1.25rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0.5rem 0;
  @media screen and (min-width: 1024px) {
    gap: 0 1.25rem;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
  }
`;

const SampleDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 240px 240px;
    column-gap: 2rem;
  }
`;

const AdditionalInformation = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
  }
`;

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    margin-top: 1.75rem;
  }
`;

const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const FormCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    font-weight: ${({ selected }) => (selected ? "700" : "300")};
    font-size: ${({ small }) => (small ? "0.75rem" : "1rem")};
    transition: all 0.3s ease-out;
    a {
      color: var(--genseq-blue);
      text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
    }
  }
  @media screen and (min-width: 780px) {
    margin-left: ${({ small }) => (small ? "-0.5rem" : "0")};
  }
`;

const FormCheckbox = styled.input`
  align-self: start;
  appearance: none;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
  height: 1.5rem;
  margin-top: 0.125rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: 0.5s;
  width: 1.5rem;
  &:checked:before {
    color: var(--genseq-orange);
    content: "✓";
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -60%);
    width: 1rem;
  }
  &:active {
    border: 0.125rem solid #34495e;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  resize: none;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const Response = styled(motion.p)`
  font-size: 1.25rem;
  color: var(--genseq-orange);
  margin-left: auto;
  padding: 2rem 0 1rem 0;
`;

const RecaptchaContainer = styled.div`
  margin-top: 1.25rem;
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

export default RequestQuoteForm;
