import * as React from "react";
const SvgPageNotFoundIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 674 248"
    {...props}
  >
    <path
      fill="url(#page-not-found-icon_svg__a)"
      d="M585.344 230v-42.069H474v-46.207L562.307 0h83.421v135.862H674v52.069h-28.272V230h-60.384Zm0-178.276-53.054 84.138h53.054V51.724Z"
    />
    <path
      fill="url(#page-not-found-icon_svg__b)"
      d="M111.344 230v-42.069H0v-46.207L88.307 0h83.421v135.862H200v52.069h-28.272V230h-60.384Zm0-178.276L58.29 135.862h53.054V51.724Z"
    />
    <path
      fill="url(#page-not-found-icon_svg__c)"
      d="M237 128.827C237 68.032 268.818 10 337 10s100 58.032 100 118.827C437 189.623 405.182 248 337 248s-100-58.377-100-119.173Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M336.994 105.431 372.425 70l23.567 23.567-35.431 35.431L396 164.438l-23.566 23.566-35.44-35.439-35.427 35.428L278 164.426l35.428-35.428-35.419-35.42 23.566-23.567 35.419 35.42Z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="page-not-found-icon_svg__a"
        x1={574}
        x2={574}
        y1={0}
        y2={230}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.primary} />
        <stop offset={1} stopColor={props.primary} />
      </linearGradient>
      <linearGradient
        id="page-not-found-icon_svg__b"
        x1={100}
        x2={100}
        y1={0}
        y2={230}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.primary} />
        <stop offset={1} stopColor={props.primary} />
      </linearGradient>
      <radialGradient
        id="page-not-found-icon_svg__c"
        cx={0}
        cy={0}
        r={1}
        gradientTransform="matrix(0 119 -100 0 337 129)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={props.primary} />
        <stop offset={0.525} stopColor={props.primary} />
        <stop offset={0.995} stopColor={props.primary} />
      </radialGradient>
    </defs>
  </svg>
);
export default SvgPageNotFoundIcon;
