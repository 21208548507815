import * as React from "react";
const SvgArrhythmogenicRightVentricularCardiomyopathyPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M32.153 48.454c10.553-8.106 13.814-28.352-2.355-35.327M23.852 5.699c-.589-.853-1.228-1.358-2.088-1.82a.453.453 0 0 1-.241-.45l.211-1.745a.453.453 0 0 0-.393-.5l-1.495-.18a.455.455 0 0 0-.504.39l-.149 1.236a.45.45 0 0 1-.461.395c-.428-.016-.86 0-1.292.068a.446.446 0 0 1-.491-.324l-.386-1.37a.45.45 0 0 0-.554-.312l-1.453.4a.446.446 0 0 0-.313.55l.482 1.716a.459.459 0 0 1-.182.491c-.322.227-.622.48-.897.753a.457.457 0 0 1-.577.042l-1.486-1.06a.46.46 0 0 0-.631.102l-.88 1.214a.452.452 0 0 0 .1.627L11.85 7.12a.447.447 0 0 1 .16.513 6.626 6.626 0 0 0-.088 3.897c.66 2.363 2.52 4.078 4.739 4.679"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.107 20.758c3.372.176 5.773-1.707 6.556-8.03C21.76 3.874 32.826 2.227 32.826 2.227a.525.525 0 0 1 .678.294L35.888 8.6a.519.519 0 0 1-.296.672c-3.892 1.535-9.728 3.919-7.513 14.866"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.63 23.289c-.687 1.425-2.33 1.63-2.516 2.698-.161.917 4.032 1.186 4.159 2.254.114.95-2.122 2.636-1.813 3.632.292.937 5.082.244 5.527 1.165.445.92-2.003 3.443-1.444 4.275.559.832 3.85-.429 4.523.29.716.76-.707 3.969.101 4.51.873.59 3.172-1.992 4.117-1.744.944.248-1.669 4.725 2.554 4.204"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.396 30.444c0 3.283 8.538 7.252-.593 18.556C18.05 46.642 9.491 34.673 7.336 27.22c-1.961-6.786 5.484-9.85 1.338-15.825l-1.19-1.172a.18.18 0 0 1 0-.257l3.231-3.56"
    />
  </svg>
);
export default SvgArrhythmogenicRightVentricularCardiomyopathyPanel;
