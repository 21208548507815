import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Label, Input } from "../../FormComponents";
import { Button } from "../../../../../components";

const BasicInformation = ({
  formValues,
  handleInputChange,
  setFormPage,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setMessage("");

    if (
      (formValues.name.length > 1 &&
        formValues.institution.length > 1 &&
        formValues.department.length > 1 &&
        formValues.medicalRegistrationNumberCoruNumber.length > 1 &&
      formValues.email.includes("@"))
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    formValues.name,
    formValues.institution,
    formValues.department,
    formValues.medicalRegistrationNumberCoruNumber,
    formValues.email,
  ]);

  const handleNextClick = (e) => {
    e.preventDefault();
    if(!formValues.name.includes(' ')) {
      setMessage('You must provide a full name');
      return;
    } 
    setFormPage(2);
  };

  return (
    <Fields>
      <p>Basic Information</p>
      <small style={{ marginTop: '0.5rem' }}>
          Fields marked with an <span>*</span> are mandatory
      </small>
      <Label>
        <p>
          Full Name<span>*</span>
        </p>
        <Input
          type="name"
          name="name"
          id="name"
          placeholder="Enter Full Name"
          value={formValues.name}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Institution<span>*</span>
        </p>
        <Input
          type="text"
          name="institution"
          id="institution"
          placeholder="Enter Institution"
          value={formValues.institution}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Department<span>*</span>
        </p>
        <Input
          type="text"
          name="department"
          id="department"
          placeholder="Enter Department"
          value={formValues.department}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Medical Registration Number / CORU Number<span>*</span>
        </p>
        <Input
          type="text"
          name="medicalRegistrationNumberCoruNumber"
          id="medicalRegistrationNumberCoruNumber"
          value={formValues.medicalRegistrationNumberCoruNumber}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Email<span>*</span>
        </p>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Enter Email"
          value={formValues.email}
          onChange={(e) => {
            handleInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>Phone Number</p>
        <Input
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="Enter Phone Number"
          value={formValues.phoneNumber}
          onChange={(e) => {
            handleInputChange(e);
          }}
        />
      </Label>
      <Button
        type="button"
        solid="var(--genseq-blue)"
        maxWidth="220px"
        text="Next"
        disabled={disabled}
        onClick={handleNextClick}
      />
    </Fields>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;

export default BasicInformation;
