import * as React from "react";
const SvgHereditaryBreastAndGynaecologicalCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 46 52"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m34.392 34.977-2.55 1.542c-.482.29-1.355 9.809 2.55 14.481M39.393 28.577h2.274c.522 0 1.691-1.399 1.445-1.864-.34-.64-.63-1.251-.576-1.433.079-.284.54-.364 1.624-1.015 1.083-.646.961-1.542.522-2.23-.44-.69-3.372-4.543-3.448-5.718-.05-.759.616-2.917-.039-5.92-.486-2.239-1.824-4.722-4.101-5.994M39.293 35.917c1.827-.713 1.641-2.308 1.641-3.241 0-1.26 1.72-2.172.808-4.043"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.258 32.997c2.481.871 8.403 3.63 10.034 2.92M10 12.806c-.2.857-.3 1.693-.3 2.483 0 5.85 3.482 11.98 4.483 14.18.5 1.14.694 2.322.687 3.529M25.738 24.612c-1.42-.616-3.172-1.76-3.172-3.151v-2.668c0-.91.587-1.78 1.82-1.78.966 0 2.17.028 2.729.888 0 0 3.79-1.056 4.133-6.56.344-5.54 5.607-4.445 5.607-8.005C35.035 1.556 29.035 1 27.118 1 20.925 1 16.366 3.346 13.52 6.385"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M14.61 30.731s3.404-1.266 5.224-4.822M9.362 18.51c-18.834-1.97-.78-26.168 5.002-12.974"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10.091 51H2.736c-.97 0-1.466-.539-1.466-1.595V38.32c0-1.053.497-1.595 1.466-1.595h19.827c.973 0 1.467.539 1.467 1.595v11.085c0 1.053-.498 1.595-1.467 1.595h-9.536M20.283 42.75h-3.197M20.283 40.219h-3.197M20.283 47.713h-3.197M14.244 44h-3.197M14.244 40.219h-3.197M8.197 42.006H5M8.197 44.702H5M14.244 47.713h-3.197M8.197 47.713H5"
    />
  </svg>
);
export default SvgHereditaryBreastAndGynaecologicalCancerPanel;
