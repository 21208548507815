import * as React from "react";
const SvgCompetitivePricing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 43"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M41.56 19.222c.07.667.098 1.348.098 2.043 0 11.035-9.105 20-20.329 20-11.223 0-20.329-8.951-20.329-20 0-11.05 9.106-20 20.33-20 4.686 0 9.006 1.556 12.437 4.183"
    />
    <path
      stroke="#36CBFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m31.297 12.662 1.228-1.21.847-.833 9.501-9.34L49 7.31 21.95 33.899 5.689 17.915l6.127-6.032L21.95 21.85l8.075-7.95"
    />
  </svg>
);
export default SvgCompetitivePricing;
