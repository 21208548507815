import React, { useEffect, useState, createRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Recaptcha from "react-google-recaptcha";
import { Button } from "../../../../components";

const resetFormValues = () => ({
  name: "",
  email: "",
  number: "",
  jobTitle: "",
  organisation: "",
  message: "",
  contactConsent: false,
  mailingListConsent: false,
  privacyPolicyConsent: false,
  botField: "",
});

const ContactUsForm = ({ closeContactFormDialog }) => {
  const [formValues, setFormValues] = useState(resetFormValues());
  const [isMessageSending, setIsMessageSending] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const recaptchaRef = createRef();

  const encodeData = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => formData.append(key, data[key]));

    return formData;
  };

  const sendFormToNetlify = async (e) => {
    setIsMessageSending(true);

    const form = e.target;

    const recaptchaValue = recaptchaRef.current.getValue();

    const options = {
      method: "POST",
      body: encodeData({
        "form-name": form.getAttribute("name"),
        name: formValues.name,
        email: formValues.email,
        number: formValues.number,
        jobTitle: formValues.jobTitle,
        organisation: formValues.organisation,
        message: formValues.message,
        contactConsent: formValues.contactConsent,
        mailingListConsent: formValues.mailingListConsent,
        privacyPolicyConsent: formValues.privacyPolicyConsent,
      }),
    };

    return fetch("/", options)
      .then((response) => response)
      .catch((error) => console.error(error));
  };

  const resetForm = () => {
    setFormValues(resetFormValues());
    setIsMessageSending(false);
    setIsMessageSent(true);
    recaptchaRef.current.execute();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    if (formValues.name.length < 1) {
      document.getElementById("name").focus();
      return;
    }

    if (formValues.email.length < 1) {
      document.getElementById("email").focus();
      return;
    }

    if (formValues.organisation.length < 1) {
      document.getElementById("organisation").focus();
      return;
    }

    if (!formValues.contactConsent) {
      document.getElementById("contactConsent").focus();
      return;
    }

    if (!formValues.privacyPolicyConsent) {
      document.getElementById("privacyPolicyConsent").focus();
      return;
    }

    if (!recaptchaValue) {
      document.getElementById("rc-anchor-container")?.focus();
      return;
    }

    sendFormToNetlify(e).then(() =>
      setTimeout(() => {
        resetForm();
      }, 1750)
    );
  };

  const checkIfMessageAlreadySent = () => {
    if (isMessageSent) {
      setIsMessageSent(false);
    }
  };

  useEffect(() => {
    if (formValues.contactConsent) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValues.contactConsent]);

  useEffect(() => {
    if (isMessageSent) {
      document.getElementById("thank-you")?.focus();
    }
  }, [isMessageSent]);

  const handleInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;
    setFormValues((currentValues) => ({
      ...currentValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Form
      name="contact"
      method="POST"
      id="contact"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      <SectionTitle>Contact Us</SectionTitle>
      <SectionDescription>
        <p>Write us a message and tell us how we can help.</p>
        <small>
          Fields marked with an <span>*</span> are mandatory
        </small>
      </SectionDescription>
      <Input type="hidden" name="form-name" value="Level 1 Submission" />
      <p hidden>
        <Label>
          Don’t fill this out:
          <Input
            name="bot-field"
            value={formValues.botField}
            onChange={handleInputChange}
          />
        </Label>
      </p>
      <Label>
        <p>
          Full Name<span>*</span>
        </p>
        <Input
          type="name"
          name="name"
          id="name"
          placeholder="Enter Full Name"
          value={formValues.name}
          onChange={handleInputChange}
          required
        />
      </Label>
      <Label>
        <p>
          Email<span>*</span>
        </p>
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Enter Email"
          value={formValues.email}
          onChange={handleInputChange}
          required
        />
      </Label>
      <Label>
        <p>Contact Number</p>
        <Input
          type="tel"
          name="number"
          id="number"
          placeholder="Enter Contact Number"
          value={formValues.number}
          onChange={handleInputChange}
        />
      </Label>
      <Label>
        <p>Job Title</p>
        <Input
          type="text"
          name="jobTitle"
          id="jobTitle"
          placeholder="Enter Job Title"
          value={formValues.jobTitle}
          onChange={handleInputChange}
        />
      </Label>
      <Label>
        <p>
          Organisation Name<span>*</span>
        </p>
        <Input
          type="text"
          name="organisation"
          id="organisation"
          placeholder="Enter Organisation Name"
          value={formValues.organisation}
          onChange={handleInputChange}
          required
        />
      </Label>
      <Label>
        <p>Message</p>
        <TextArea
          name="message"
          id="message"
          placeholder="Enter Message"
          value={formValues.message}
          rows="4"
          onChange={handleInputChange}
        />
      </Label>
      <Label>
        <FormCheck>
          <FormCheckbox
            type="checkbox"
            id="contactConsent"
            name="contactConsent"
            onChange={handleInputChange}
            checked={formValues.contactConsent}
            value={formValues.contactConsent}
            required
          />
          <p>
            I consent to being contacted by Genseq regarding my inquiry.
            <span>*</span>
          </p>
        </FormCheck>
      </Label>
      <Label>
        <FormCheck small>
          <FormCheckbox
            type="checkbox"
            id="privacyPolicyConsent"
            name="privacyPolicyConsent"
            onChange={handleInputChange}
            checked={formValues.privacyPolicyConsent}
            value={formValues.privacyPolicyConsent}
          />
          <p>
            I understand that the information provided by me is subject to the
            Privacy Policy.<span>*</span> (
            <a href="/privacy-policy">See Privacy Policy</a>)
          </p>
        </FormCheck>
      </Label>
      <Label>
        <FormCheck>
          <FormCheckbox
            type="checkbox"
            id="mailingListConsent"
            name="mailingListConsent"
            onChange={handleInputChange}
            checked={formValues.mailingListConsent}
            value={formValues.mailingListConsent}
          />
          <p>
            I would like join the Genseq mailing list to stay updated on the
            latest news and offers.
          </p>
        </FormCheck>
      </Label>
      <RecaptchaContainer>
        <Recaptcha
          ref={recaptchaRef}
          sitekey="6Lch700pAAAAALDw2GocqmC-3fbqhqIPeJY2lkEo"
          size="normal"
          id="recaptcha-google"
          onExpired={() => {
            setDisabled(true);
            recaptchaRef?.execute();
          }}
          onChange={() => {
            if (
              formValues.contactConsent &&
              formValues.privacyPolicyConsent &&
              recaptchaRef.current.getValue()
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        />
      </RecaptchaContainer>
      {isMessageSent ? (
        <Response
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          id="contact-form-success-message"
          className="contact-form-success-message"
        >
          Thank you, we'll be in touch soon.
        </Response>
      ) : (
        <Button
          type="submit"
          solid="var(--genseq-blue)"
          maxWidth="220px"
          id="contact-us-submit"
          text={isMessageSending ? "Sending Message" : "Send Message"}
          disabled={disabled}
        />
      )}
    </Form>
  );
};

const Form = styled.form`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  max-width: 480px;
  margin: 0 auto;
  padding: 1.875rem 1.25rem;
  span {
    color: var(--genseq-orange);
  }
  button {
    align-self: flex-end;
    margin-top: 0.875rem;
  }
  @media screen and (min-width: 780px) {
    padding: 1.875rem;
  }
`;

const SectionTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: left;
  width: 100%;
`;

const SectionDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.625rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 0.625rem;
  padding-top: 1.25rem;
`;

const Input = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const FormCheck = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  p {
    font-weight: 300;
    font-size: 0.75rem;
    a {
      color: var(--genseq-blue);
    }
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const FormCheckbox = styled.input`
  appearance: none;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  cursor: pointer;
  height: 1.5rem;
  margin-top: 0.125rem;
  outline: none;
  padding: 0.625rem;
  position: relative;
  transition: 0.5s;
  width: 1.5rem;

  &:checked:before {
    color: var(--genseq-orange);
    content: "✓";
    font-size: 1rem;
    height: 1rem;
    left: 50%;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translate(-40%, -60%);
    width: 1rem;
  }
  &:active {
    border: 0.125rem solid #34495e;
  }
  &:focus {
    border-color: var(--genseq-orange);
  }
`;

const TextArea = styled.textarea`
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  color: var(--genseq-blue);
  font-family: "Outfit", sans-serif;
  font-size: 1rem;
  padding: 1rem 0.5rem;
  resize: none;
  &::placeholder {
    color: #e5e5e5;
    font-family: "Outfit", sans-serif;
    font-size: 1rem;
  }
  &:focus {
    outline: none;
    border-color: var(--genseq-orange);
  }
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const Response = styled(motion.p)`
  font-size: 1.25rem;
  color: var(--genseq-orange);
  margin-left: auto;
  padding: 2rem 0 1rem 0;
`;

const RecaptchaContainer = styled.div`
  margin-top: 1.25rem;
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

export default ContactUsForm;
