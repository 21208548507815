import React from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { ServiceAccordion } from "../../../../../components";
import {
  HighProbability,
  LowProbability,
  NoResult,
} from "../../../../../assets/svgs";

const HowItWorks = ({
  howDoesTestWork,
  whatResultsMeanIntro,
  whatResultsMean,
  multiplePregnancy,
  testLimitationsIntro,
  testLimitations,
}) => {
  return (
    <Article>
      <ArticleSection>
        <Subtitle>How does PrenatalSEQ work?</Subtitle>
        <p>
          <Markup content={howDoesTestWork} />
        </p>
      </ArticleSection>
      <ArticleSection>
        <Subtitle>What do the results mean?</Subtitle>
        <Markup content={whatResultsMeanIntro} />
      </ArticleSection>
      <Graphics>
        {whatResultsMean.map((result, index) => (
          <GraphicCard key={result.title}>
            {index === 0 ? (
              <HighProbability />
            ) : index === 1 ? (
              <LowProbability />
            ) : (
              <NoResult />
            )}
            <h5>{result.title}</h5>
            <p>{result.description}</p>
            <small>{result.smallText}</small>
          </GraphicCard>
        ))}
      </Graphics>
      <GraphicCard>
        <p>
          Fetal sex will be reported as “male” when a Y chromosome is detected
          or “female” when no Y chromosome is detected in the sample.
        </p>
      </GraphicCard>
      <ArticleSection>
        <Subtitle>What if I have a multiple pregnancy?</Subtitle>
        <Markup content={multiplePregnancy} />
      </ArticleSection>
      <ArticleSection>
        <Subtitle>What are the test limitations?</Subtitle>
        <Markup content={testLimitationsIntro} />
        <br />
        <ServiceAccordion
          style={{ marginLeft: "0" }}
          reasons={testLimitations}
        />
      </ArticleSection>
    </Article>
  );
};

const Article = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (min-width: 1024px) {
    gap: 3rem;
  }
`;

const ArticleSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 780px;
  ul,
  ol {
    margin-left: 1.625rem;
  }
  strong {
    ul,
    ol {
      margin-left: 1.625rem;
    }
  }
  .gatsby-image-wrapper {
    max-width: 500px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  a {
    color: var(--genseq-purple);
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  @media screen and (min-width: 1024px) {
    .gatsby-image-wrapper {
      max-width: 100%;
    }
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: capitalize;
`;

const Graphics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-top: -2rem;
  @media screen and (min-width: 780px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.75rem;
  }
`;

const GraphicCard = styled.div`
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 2.25rem 2rem;
  svg {
    width: 60px;
    height: 60px;
  }
  h5 {
    font-size: 1.25rem;
  }
`;

export default HowItWorks;
