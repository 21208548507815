import * as React from "react";
const SvgResults = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 56"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#results_svg__a)"
    >
      <path
        stroke="#061C57"
        d="M17.673 55.183H3.684c-1.58 0-2.857-1.266-2.857-2.822V3.639C.827 2.08 2.11.817 3.684.817h33.94c1.579 0 2.856 1.266 2.856 2.822v26.123"
      />
      <path
        stroke="#061C57"
        d="M8.005 6.327v18.828h25.28M12.545 25.155v-3.537M16.833 25.155v-7.658M21.125 25.155v-4.754M25.417 25.155v-7.05M29.709 25.155v-9.234M21.443 55.183h.74"
      />
      <path
        stroke="#061C57"
        d="m30.85 8.16-7.678 6.315-5.193-3.803-5.434 5.249M21.27 43.751c0 3.631-2.982 6.572-6.657 6.572-3.676 0-6.654-2.945-6.654-6.572h6.654V37.18c3.675 0 6.657 2.945 6.657 6.572ZM10.957 33.565v6.572H4.304c0-3.632 2.981-6.572 6.653-6.572Z"
      />
      <path
        stroke="#1AE299"
        d="M26.393 34.194c0 10.492 14.092 10.492 14.092 20.985"
      />
      <path
        stroke="#1AE299"
        d="M40.485 34.194c0 10.492-14.092 10.492-14.092 20.985M40.294 36.212H30.85M38.16 40.137H33.44M27.2 53.219h9.44M28.49 49.29h4.721"
      />
    </g>
    <defs>
      <clipPath id="results_svg__a">
        <path fill="#fff" d="M0 0h41.312v56H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgResults;
