import * as React from "react";
const SvgHighQuality = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 51 50"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.63 25.007a5.424 5.424 0 0 1 5.433-5.421 5.424 5.424 0 0 1 5.434 5.421M1 25.007c0-1.587.162-3.144.457-4.643h3.18a20.483 20.483 0 0 1 2.695-6.479l-.28-.279-1.958-1.954a24.115 24.115 0 0 1 6.583-6.568l1.958 1.955.28.279a20.93 20.93 0 0 1 6.495-2.689V1.455A24.262 24.262 0 0 1 25.063 1c1.59 0 3.152.162 4.654.455V4.63a20.93 20.93 0 0 1 6.494 2.689l.28-.28 1.959-1.954a24.116 24.116 0 0 1 6.582 6.568l-1.958 1.954-.28.28a20.828 20.828 0 0 1 2.695 6.479h3.18c.295 1.498.457 3.056.457 4.642m-10.868 0c0-7.272-5.905-13.164-13.195-13.164s-13.195 5.892-13.195 13.164m13.195.779v-.779"
    />
    <path
      stroke="#1AE299"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M44.458 31.972h-6.215v-3.88m6.215 3.88a2.329 2.329 0 0 1 2.327-2.322 2.329 2.329 0 0 1 2.327 2.322 2.33 2.33 0 0 1-2.327 2.321 2.33 2.33 0 0 1-2.327-2.321Zm-32.604-3.88v3.88h-6.2m0 0a2.329 2.329 0 0 0-2.327-2.322A2.329 2.329 0 0 0 1 31.972a2.33 2.33 0 0 0 2.327 2.321 2.33 2.33 0 0 0 2.327-2.321Zm30.881 9.138-2.945-2.938v-4.643l-3.108-3.1M13.578 41.11l2.945-2.938v-4.643l3.107-3.1m5.434 13.943V28.886m13.18 11.607a2.33 2.33 0 0 0-2.326 2.322 2.329 2.329 0 0 0 2.326 2.32 2.329 2.329 0 0 0 2.327-2.32 2.33 2.33 0 0 0-2.327-2.322Zm-13.18 3.864a2.33 2.33 0 0 1 2.327 2.322A2.33 2.33 0 0 1 25.063 49a2.33 2.33 0 0 1-2.327-2.321 2.33 2.33 0 0 1 2.327-2.322Zm-13.195-3.864a2.33 2.33 0 0 1 2.327 2.322 2.329 2.329 0 0 1-2.327 2.32 2.329 2.329 0 0 1-2.327-2.32 2.33 2.33 0 0 1 2.327-2.322Z"
    />
  </svg>
);
export default SvgHighQuality;
