exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-clinical-portal-jsx": () => import("./../../../src/pages/clinical-portal.jsx" /* webpackChunkName: "component---src-pages-clinical-portal-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-services-biobanking-and-sample-management-jsx": () => import("./../../../src/pages/services/biobanking-and-sample-management.jsx" /* webpackChunkName: "component---src-pages-services-biobanking-and-sample-management-jsx" */),
  "component---src-pages-services-biopharma-and-research-index-jsx": () => import("./../../../src/pages/services/biopharma-and-research/index.jsx" /* webpackChunkName: "component---src-pages-services-biopharma-and-research-index-jsx" */),
  "component---src-pages-services-biopharma-and-research-resources-downloadable-forms-jsx": () => import("./../../../src/pages/services/biopharma-and-research/resources/downloadable-forms.jsx" /* webpackChunkName: "component---src-pages-services-biopharma-and-research-resources-downloadable-forms-jsx" */),
  "component---src-pages-services-biopharma-and-research-resources-index-jsx": () => import("./../../../src/pages/services/biopharma-and-research/resources/index.jsx" /* webpackChunkName: "component---src-pages-services-biopharma-and-research-resources-index-jsx" */),
  "component---src-pages-services-biopharma-and-research-resources-sample-requirements-jsx": () => import("./../../../src/pages/services/biopharma-and-research/resources/sample-requirements.jsx" /* webpackChunkName: "component---src-pages-services-biopharma-and-research-resources-sample-requirements-jsx" */),
  "component---src-pages-services-clinical-index-jsx": () => import("./../../../src/pages/services/clinical/index.jsx" /* webpackChunkName: "component---src-pages-services-clinical-index-jsx" */),
  "component---src-pages-services-clinical-prenatal-seq-healthcare-professional-resources-jsx": () => import("./../../../src/pages/services/clinical/prenatal-seq/healthcare-professional-resources.jsx" /* webpackChunkName: "component---src-pages-services-clinical-prenatal-seq-healthcare-professional-resources-jsx" */),
  "component---src-pages-services-clinical-prenatal-seq-index-jsx": () => import("./../../../src/pages/services/clinical/prenatal-seq/index.jsx" /* webpackChunkName: "component---src-pages-services-clinical-prenatal-seq-index-jsx" */),
  "component---src-pages-services-clinical-prenatal-seq-patient-resources-jsx": () => import("./../../../src/pages/services/clinical/prenatal-seq/patient-resources.jsx" /* webpackChunkName: "component---src-pages-services-clinical-prenatal-seq-patient-resources-jsx" */),
  "component---src-pages-services-clinical-resources-downloadable-forms-jsx": () => import("./../../../src/pages/services/clinical/resources/downloadable-forms.jsx" /* webpackChunkName: "component---src-pages-services-clinical-resources-downloadable-forms-jsx" */),
  "component---src-pages-services-clinical-resources-index-jsx": () => import("./../../../src/pages/services/clinical/resources/index.jsx" /* webpackChunkName: "component---src-pages-services-clinical-resources-index-jsx" */),
  "component---src-pages-services-clinical-resources-sample-requirements-jsx": () => import("./../../../src/pages/services/clinical/resources/sample-requirements.jsx" /* webpackChunkName: "component---src-pages-services-clinical-resources-sample-requirements-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-biopharma-research-service-jsx": () => import("./../../../src/templates/biopharmaResearchService.jsx" /* webpackChunkName: "component---src-templates-biopharma-research-service-jsx" */),
  "component---src-templates-clinical-category-jsx": () => import("./../../../src/templates/clinicalCategory.jsx" /* webpackChunkName: "component---src-templates-clinical-category-jsx" */),
  "component---src-templates-clinical-service-jsx": () => import("./../../../src/templates/clinicalService.jsx" /* webpackChunkName: "component---src-templates-clinical-service-jsx" */),
  "component---src-templates-clinical-sub-category-jsx": () => import("./../../../src/templates/clinicalSubCategory.jsx" /* webpackChunkName: "component---src-templates-clinical-sub-category-jsx" */)
}

