import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const OurSuppliers = () => {
  const { logos } = useStaticQuery(
    graphql`
      query {
        logos: allFile(
          filter: {
            relativeDirectory: { eq: "assets/images/our-team/suppliers" }
          }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  quality: 100
                  placeholder: BLURRED
                  height: 55
                  transformOptions: { fit: CONTAIN }
                )
              }
            }
          }
        }
      }
    `
  );

  return (
    <Container id="our-suppliers">
      <div>
        <h3>Our Suppliers</h3>
        <p>
          We use best-in-class technology providers to power our laboratory
          service offerings
        </p>
      </div>
      <Track>
        {logos.edges.map((logo, i) => (
          <LogoContainer delay={i + 1} length={logos.edges.length}>
            <Logo
              image={getImage(logo.node)}
              alt={logo.node.name}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </LogoContainer>
        ))}
      </Track>
    </Container>
  );
};

const Container = styled.section`
  grid-column: 1 / 7;
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
  @media screen and (min-width: 1024px) {
    grid-column: 3 / 11;
    padding: 0 0 6rem 0;
    gap: 3rem;
    p {
      font-size: 1.25rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 4 / 10;
  }
`;

const Track = styled.div`
  width: 100%;
  max-width: 1024px;
  margin-inline: auto;
  position: relative;
  height: 4rem;
  overflow: hidden;
  mask-image: linear-gradient(
    to right,
    rgba(248, 248, 248, 0),
    rgba(248, 248, 248, 1) 10%,
    rgba(248, 248, 248, 1) 90%,
    rgba(248, 248, 248, 0)
  );
`;

const LogoContainer = styled(motion.div)`
  min-height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  min-width: 160px;
  padding: 0 1rem;
  position: absolute;
  left: ${({ length }) => `max(calc(160px * ${length}), 100%)`};
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: ${({ delay, length }) =>
    `calc(30s / ${length} * (${length} - ${delay}) * -1)`};
  @keyframes scrollLeft {
    to {
      left: -160px;
    }
  }
`;

const Logo = styled(GatsbyImage)`
  max-width: 7.5rem;
  width: 100%;
  height: 100%;
`;

export default OurSuppliers;
