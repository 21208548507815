import * as React from "react";
const SvgHereditaryCancerLynchSyndrome = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 47 52"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m35.39 34.977-2.55 1.542c-.482.29-1.355 9.809 2.55 14.481M40.392 28.577h2.274c.522 0 1.691-1.399 1.444-1.864-.34-.64-.629-1.251-.575-1.433.078-.284.54-.364 1.623-1.015 1.084-.646.962-1.542.522-2.23-.44-.69-3.372-4.543-3.447-5.718-.05-.759.615-2.917-.04-5.92-.486-2.239-1.823-4.722-4.1-5.994M40.293 35.917c1.827-.713 1.641-2.308 1.641-3.241 0-1.26 1.72-2.172.809-4.043"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M30.26 32.997c2.481.871 8.403 3.63 10.033 2.92M11 12.806c-.2.857-.3 1.693-.3 2.483 0 5.85 3.482 11.98 4.484 14.18.5 1.14.693 2.322.686 3.529M26.737 24.612c-1.42-.616-3.172-1.76-3.172-3.151v-2.668c0-.91.586-1.78 1.82-1.78.965 0 2.17.028 2.728.888 0 0 3.79-1.056 4.134-6.56.343-5.54 5.607-4.445 5.607-8.005C36.034 1.556 30.034 1 28.117 1c-6.193 0-10.753 2.346-13.599 5.385"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15.609 30.731s3.404-1.266 5.224-4.822M10.362 18.51c-18.834-1.97-.78-26.169 5.002-12.974"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1.828 39.667C9.529 45.04 16.15 34.2 23.85 39.57"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.45 28.826c7.7 5.373 1.079 16.214 8.78 21.584M9.84 30.006 5.403 37.27M11.72 33.656 9.5 37.289M16.168 48.788l4.437-7.264M13.893 45.782l2.219-3.631"
    />
  </svg>
);
export default SvgHereditaryCancerLynchSyndrome;
