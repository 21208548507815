import * as React from "react";
const SvgHealthcareProfessional = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 58"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.102 32.091c-.345 1.628-.553 2.618-1.408 3.645L4.76 41.182A4.973 4.973 0 0 0 2 44.165c-.302.91-.654 2.63-1 4.765M29.781 32.091c.346 1.628.554 2.618 1.409 3.645l13.934 5.446a4.973 4.973 0 0 1 2.76 2.983c.34 1.015.736 3.05 1.12 5.545M33.15 3.791s-.012-.006-.012-.012C31.49 2.176 28.925 1.006 24.938 1c-3.986 0-6.551 1.176-8.199 2.779 0 0-.012.006-.012.012M14.206 16.038c7.4-1.07 12.443-2.296 15.964-7.872a.427.427 0 0 1 .409-.192.41.41 0 0 1 .346.285c.666 2.08 2.483 7.135 4.703 8.218-.88 8.113-2.326 12.501-6.338 16.022-.93.75-1.66 1.096-2.792 1.275 0 0-.088.013-.232.037-.258.038-.46.068-.667.087-.207.019-.409.03-.666.03-.516 0-.818-.042-1.327-.117-.139-.024-.233-.037-.233-.037-1.125-.173-1.861-.526-2.792-1.275-4.08-3.583-5.508-8.07-6.382-16.461h.007Z"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M33.14 3.779c11.432 9.697 4.924 25.286 5.188 28.807.1 1.362-3.565 3.398-4.772 4.072M16.57 36.665l-.144-.038M16.745 3.779c-11.432 9.697-4.924 25.286-5.188 28.807-.1 1.343 3.622 3.36 4.867 4.035M13.447 47.68c-1.195-3.057-1.535-6.331-.673-9.63"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.014 49.71c0 1.343-1.025 2.432-2.282 2.432-1.258 0-2.283-1.09-2.283-2.432 0-1.343 1.025-2.432 2.283-2.432 1.257 0 2.282 1.089 2.282 2.432ZM36.983 43.546c.245-1.665.252-3.892-.182-5.613"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m31.77 54.543-.308 1.3c-1.126-.267-2.075-1.411-1.748-2.55l2.333-6.906c.742-2.061 2.508-3.416 4.835-2.872 2.326.545 3.301 2.537 3.037 4.71l-1.025 7.21c-.22 1.163-1.584 1.77-2.71 1.503l.308-1.3M18.41 35.848l.346 2.982c.05.446.296.817.522 1.201.868 1.485 2.301 3.187 3.961 4.945a2.316 2.316 0 0 0 3.352 0c1.66-1.758 3.1-3.454 3.961-4.945.226-.39.471-.761.522-1.2l.346-2.996"
    />
  </svg>
);
export default SvgHealthcareProfessional;
