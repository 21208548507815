import React from "react";
import styled from "styled-components";
import {
  Conversation,
  ConsentForm,
  Ultrasound,
  BloodSample,
  Results,
} from "../../../../../assets/svgs";

const GettingATest = ({ howToGetSteps }) => {
  const SVGMapper = {
    conversation: Conversation,
    consentForm: ConsentForm,
    ultrasound: Ultrasound,
    bloodSample: BloodSample,
    results: Results,
  };
  return (
    <Article>
      <ArticleSection>
        <Subtitle>How to get a PrenatalSEQ screening test?</Subtitle>
        <List>
          {howToGetSteps.map((step) => {
            const SVG = SVGMapper[step.svg];
            return (
              <ListItem key={step.svg}>
                <SVG />
                <p>{step.text}</p>
              </ListItem>
            );
          })}
        </List>
      </ArticleSection>
    </Article>
  );
};

const Article = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (min-width: 1024px) {
    gap: 3rem;
  }
`;

const ArticleSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 780px;
  ul,
  ol {
    margin-left: 1.625rem;
  }
  strong {
    ul,
    ol {
      margin-left: 1.625rem;
    }
  }
  .gatsby-image-wrapper {
    max-width: 500px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  a {
    color: var(--genseq-purple);
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  @media screen and (min-width: 1024px) {
    .gatsby-image-wrapper {
      max-width: 100%;
    }
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: capitalize;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 2rem 2rem;
  @media screen and (min-width: 780px) {
    gap: 2rem;
    margin: 0 2rem;
  }
`;

const ListItem = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: 56px auto;
  gap: 1.5rem;
  svg {
    max-height: 56px;
    margin: 0 auto;
  }
  @media screen and (min-width: 780px) {
    grid-template-columns: 56px auto;
    grid-template-rows: 1fr;
    gap: 3rem;
  }
`;

export default GettingATest;
