import React from "react";
import styled from "styled-components";
import { Markup } from 'interweave';

const TellMeMore = ({
  whatIs,
  whatDoesTheScreenTestFor,
  whoTestForContinued,
}) => {
  return (
    <Article>
      <ArticleSection>
        <Subtitle>What is PrenatalSEQ?</Subtitle>
        <Markup content={whatIs} />
      </ArticleSection>
      <ArticleSection>
        <Subtitle>What Does PrenatalSEQ Screen For?</Subtitle>
        <p>
          <Markup content={whatDoesTheScreenTestFor} />
        </p>
      </ArticleSection>
      <ArticleSection>
        <Subtitle>Who is PrenatalSEQ for?</Subtitle>
        <p>
          <Markup content={whoTestForContinued} />
        </p>
      </ArticleSection>
    </Article>
  );
};

const Article = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media screen and (min-width: 1024px) {
    gap: 3rem;
  }
`;

const ArticleSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 780px;
  ul,
  ol {
    margin-left: 1.625rem;
  }
  strong {
    ul,
    ol {
      margin-left: 1.625rem;
    }
  }
  .gatsby-image-wrapper {
    max-width: 500px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  a {
    color: var(--genseq-purple);
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  @media screen and (min-width: 1024px) {
    .gatsby-image-wrapper {
      max-width: 100%;
    }
  }
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1.25rem;
  text-transform: capitalize;
`;

export default TellMeMore;
