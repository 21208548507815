import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { motion, useInView } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { Markup } from "interweave";
import {
  Laboratory1,
  Laboratory2,
  Laboratory3,
  Laboratory4,
  Laboratory5,
  Team1,
  Team2,
  Team3,
} from "../../../assets/animated-svgs";

const LargeCarousel = ({ slides, minHeight, unequal }) => {
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const [[page, direction], setPage] = useState([0, 0]);
  const [pause, setPause] = useState(true);
  const index = wrap(0, slides.length, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  let timer;

  useEffect(() => {
    clearTimeout(timer);
    if (!pause) {
      timer = setTimeout(() => {
        paginate(1);
      }, 5000);
    }
  }, [page, pause]);

  const SVGMapper = {
    team1: Team1,
    team2: Team2,
    team3: Team3,
    laboratory1: Laboratory1,
    laboratory2: Laboratory2,
    laboratory3: Laboratory3,
    laboratory4: Laboratory4,
    laboratory5: Laboratory5,
  };

  useEffect(() => {
    if (isInView) {
      setPause(false);
      setPage([0, 1]);
    }
  }, [isInView]);

  return (
    <Carousel ref={ref}>
      <Static columns={slides.length} unequal={unequal}>
        {slides.map((slide, i) => {
          const SVG = SVGMapper[slide.svg];
          return (
            <div
              role="button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                clearTimeout(timer);
                setPause(true);
                setPage([i, 1]);
              }}
            >
              <SVGContainer>
                <SVG color={i === index ? slide.color : "#061C57"} />
              </SVGContainer>
              <Title highlighted={i === index}>
                <Markup content={slide.title} />
              </Title>
            </div>
          );
        })}
      </Static>
      <Indicator
        multiplier={index + 1}
        divider={slides.length}
        color={slides[index].color || "#1AE299"}
        unequal={unequal}
      />
      <Track>
        <Slide
          key={page}
          custom={direction}
          minHeight={minHeight}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            x: { type: "spring", stiffness: 300, damping: 300 },
            opacity: { duration: 1 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            clearTimeout(timer);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          <Markup content={slides[index].description} />
        </Slide>
      </Track>
    </Carousel>
  );
};

const Carousel = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  width: 100%;
`;

const Track = styled(motion.div)`
  cursor: grab;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  position: relative;
  &:active {
    cursor: grabbing;
  }
`;

const Slide = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  span {
    font-size: 2.25rem;
    text-align: left;
  }
`;

const Indicator = styled.div`
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  position: relative;
  overflow: visible;
  &:before {
    content: "";
    background: ${({ color }) => color};
    width: ${({ divider, multiplier, unequal }) =>
      !unequal
        ? `calc(100% / ${divider} * ${multiplier})`
        : `calc(100% / 13 * ${
            multiplier === 3
              ? "10"
              : multiplier === 4
              ? "13"
              : `3 * ${multiplier}`
          })`};
    left: 0;
    height: 0.25rem;
    position: absolute;
    top: -1.5px;
    transition: all 0.5s ease-in-out;
  }
`;

const SVGContainer = styled.div`
  width: 100%;
  svg {
    height: 82px;
    width: auto;
  }
  margin-bottom: 1.75rem;
`;

const Static = styled.div`
  display: grid;
  grid-template-columns: ${({ columns, unequal }) =>
    !unequal ? `repeat(${columns}, 1fr)` : `3fr 3fr 4fr 3fr`};
`;

const Title = styled.h5`
  font-size: 1.25rem;
  font-weight: ${({ highlighted }) => (highlighted ? "700" : "400")};
  margin-top: ${({ highlighted }) => (highlighted ? "0.5rem" : "0")};
  transition: all 0.5s ease-in-out;
  span {
    font-size: 1.25rem;
  }
`;
export default LargeCarousel;
