import * as React from "react";
const SvgSingleGeneTest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 55 55"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m41.786 4.441-.002-.002c-1.622-1.622-4.288-1.584-5.955.083l-9.17 9.17c-1.667 1.667-1.704 4.333-.083 5.954l.003.002c1.62 1.622 4.287 1.584 5.954-.083l9.17-9.17c1.667-1.667 1.704-4.333.083-5.954ZM11.545 40.553l-1.15 1.15c-1.668 1.668-4.333 1.705-5.955.083-1.621-1.621-1.584-4.286.084-5.954l1.15-1.15M11.997 28.355l1.692-1.693c1.667-1.667 4.333-1.704 5.954-.083 1.622 1.622 1.584 4.287-.083 5.954l-1.692 1.693M20.092 26.13c1.621 1.622 4.287 1.584 5.954-.083 1.668-1.668 1.705-4.333.084-5.955-1.622-1.62-4.287-1.584-5.955.084-1.667 1.667-1.704 4.333-.083 5.954ZM52.065 14.719l-.003-.003c-1.621-1.62-4.287-1.583-5.954.084l-9.17 9.17c-1.667 1.667-1.705 4.333-.083 5.954l.002.002c1.621 1.622 4.287 1.584 5.955-.083l9.17-9.17c1.667-1.667 1.704-4.333.083-5.954ZM29.926 36.857l-.002-.002c-1.622-1.622-4.287-1.584-5.955.083l-9.17 9.17c-1.667 1.667-1.704 4.333-.083 5.954l.003.002c1.62 1.622 4.287 1.584 5.954-.083l9.17-9.17c1.667-1.667 1.704-4.333.083-5.954ZM30.37 36.408c1.62 1.621 4.287 1.584 5.954-.083 1.667-1.668 1.705-4.333.083-5.955-1.62-1.621-4.287-1.584-5.954.084-1.668 1.667-1.705 4.333-.084 5.954Z"
    />
    <path
      stroke={props.color || "#36CBFF"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m19.425 35.782-8.93-8.93-6.326 6.324 8.931 8.931 6.325-6.325ZM12.515 29.295 6.59 35.22M14.782 31.492l-5.926 5.925M17.016 33.727l-5.925 5.925"
    />
  </svg>
);
export default SvgSingleGeneTest;
