import * as React from "react";
const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path stroke="#061C57" strokeWidth={2} d="m1 1 12 11.748M1 13 13 1.25" />
  </svg>
);
export default SvgClose;
