import * as React from "react";
const SvgUltrasound = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 57 50"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M37.519 28.571H5.772M36.827 2.857H6.464c-1.976 0-3.578 1.568-3.578 3.503v25.138C2.886 33.432 4.488 35 6.464 35h30.363c1.976 0 3.578-1.568 3.578-3.503V6.36c0-1.935-1.602-3.503-3.578-3.503ZM46.622 25.969v-4.01l-2.61-6.564v-2.479c0-1.216 1.006-2.202 2.245-2.202h5.612c1.24 0 2.245.986 2.245 2.202v2.479l-2.442 6.741v3.833c0 .647-.534 1.174-1.196 1.174h-2.657c-.66 0-1.197-.525-1.197-1.174ZM44.013 15h10.101"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.646 34.812v6.536a5.795 5.795 0 0 0 5.796 5.795h15.414a6.207 6.207 0 0 0 6.207-6.206V27.143"
    />
    <path
      stroke="#645FE6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.18 7.857c-1.429.973-4.603 1.975-6.534 1.975-2.19 0-5.187-.957-6.535-1.975L9.38 18.04c1.963 3.493 6.343 6.247 12.239 6.247h.054c5.895 0 10.558-2.84 12.238-6.247l-5.73-10.182Z"
    />
    <path
      stroke="#645FE6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.76 18.982c1.094.22 2.168.313 3.084.303 2.66-.033 5.7-.613 7.738-2.142M13.709 16.429a10.49 10.49 0 0 0 2.165 1.428"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.367 31.429h-1.443"
    />
  </svg>
);
export default SvgUltrasound;
