import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";
import { PlayButton } from '../../../assets/svgs';


const Video = ({ link }) => {
    const [playing, setPlaying] = useState(true);
    return (
        <WideWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1.5 }}>
          <VideoContainer>
            <StyledReactPlayer
              height="100%"
              volume={1}
              playing={playing}
              playsinline
              controls={true}
              url={link}
              width="100%"
            />
            <Overlay
              onClick={() => (!playing ? setPlaying(true) : setPlaying(false))}
              whileHover={{
                scale: 1.075,
                transition: { duration: 0.3 },
              }}
              whileTap={{ scale: 0.9 }}
            >
              {!playing && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                >
                  <PlayButton />
                </motion.div>
              )}
            </Overlay>
          </VideoContainer>
        </WideWrapper>
    )
};

const WideWrapper = styled(motion.div)`
  border-radius: 0.625rem;
  overflow: hidden;
`;

const VideoContainer = styled(motion.div)`
  border-radius: 0.625rem;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Overlay = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    svg {
      height: 62px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 62px;
    }
  }
  @media screen and (min-width: 1024px) {
    div {
      svg {
        height: 128px;
        width: 128px;
      }
    }
  }
`;

export default Video;