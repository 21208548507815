import * as React from "react";
const SvgSqtsPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 50"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 31.595h4.08c1.79-2.353 5.43-2.394 7.277-.079l.064.08 4.561.086 2.815-6.357 2.81 12.138 2.58-5.78h1.055l.052-.063c2.213-2.664 6.394-2.772 8.754-.224h2.59"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.635 5.638c-.6-.842-1.254-1.34-2.131-1.796a.444.444 0 0 1-.247-.444l.217-1.723a.45.45 0 0 0-.402-.493l-1.526-.179a.461.461 0 0 0-.515.386l-.151 1.22a.455.455 0 0 1-.471.39c-.437-.016-.878 0-1.319.067a.455.455 0 0 1-.502-.32l-.393-1.353a.462.462 0 0 0-.566-.307l-1.483.394a.44.44 0 0 0-.32.544l.493 1.693a.446.446 0 0 1-.186.486 7.102 7.102 0 0 0-.916.742.478.478 0 0 1-.588.042L17.11 3.94a.48.48 0 0 0-.644.1l-.9 1.2c-.146.198-.098.472.105.617l1.712 1.183c.168.116.229.32.164.506a6.335 6.335 0 0 0-.09 3.847c.674 2.332 2.571 4.025 4.837 4.619"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.776 26.881c-2.434-6.565 5.598-9.723 1.366-15.62l-1.215-1.157a.173.173 0 0 1 0-.254l3.299-3.514M17.644 37.509c3.1 6.481 15.86 13.038 20.46 11.167 8.668-3.528 15.663-26.717-2.403-35.7"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.689 20.503c3.44.174 5.892-1.685 6.692-7.926C27.5 3.837 38.796 2.21 38.796 2.21a.542.542 0 0 1 .692.29l2.434 6a.507.507 0 0 1-.303.665c-3.973 1.514-9.93 3.867-7.669 14.673"
    />
  </svg>
);
export default SvgSqtsPanel;
