import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import { motion } from "framer-motion";
import { Button } from "../../../components";
import { LinkedinIcon, ChevronDown } from "../../../assets/svgs";
import { LocalContext } from "../../../context";
import { scrollToAnchor } from "../../../utils";
import ServicesMenu from "../ServicesMenu";

const DropdownNavigation = ({
  dropdownOpen,
  showOrderTestMenu,
  setShowOrderTestMenu,
}) => {
  const dropdownOpenClose = {
    hidden: { y: "100%", transition: { duration: 0.5 } },
    visible: { y: "0", transition: { duration: 0.5 } },
  };
  const {
    setShowCookieBar,
    setShowContactFormDialog,
    setShowRequestFormDialog,
    setShowRequestAccountFormDialog,
    setDropdownOpen,
    expandedServices,
    setExpandedServices
  } = useContext(LocalContext);
  const toggleExpandedServices = () => {
    setExpandedServices((prevState) => !prevState);
  };

  const cardExpanded = {
    hidden: { opacity: 0, transition: { duration: 0.75 } },
    visible: { opacity: 1, transition: { duration: 0.75 } },
  };

  return (
    <Container
      variants={dropdownOpenClose}
      initial="hidden"
      exit="hidden"
      animate={dropdownOpen ? "visible" : "hidden"}
    >
      <Links>
        <Anchor
          type="button"
          id="home-navigation"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(false);
            navigate("/");
          }}
        >
          Home
        </Anchor>
        <Anchor
          type="button"
          id="about-us-navigation"
          onClick={(e) => {
            e.preventDefault();
            navigate("/about-us");
            setDropdownOpen(false);
          }}
        >
          About Us
        </Anchor>
        <Anchor
          type="button"
          id="services-navigation"
          open={expandedServices}
          onClick={(e) => {
            e.preventDefault();
            toggleExpandedServices();
          }}
        >
          Services
        </Anchor>
        {expandedServices && (
          <motion.div
            id="expanded-services"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 1,
              ease: [0.04, 0.62, 0.23, 0.98],
              opacity: { duration: 0.1 },
            }}
          >
            <ServicesMenu invert setDropdownOpen={setDropdownOpen} />
          </motion.div>
        )}
      </Links>
      <Footer>
        <Button
          type="button"
          solid="var(--genseq-purple)"
          id="contact-us-navigation"
          text="Contact Us"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(false);
            setShowContactFormDialog(true);
          }}
        />
        <div style={{ position: "relative" }}>
          <Button
            type="button"
            solid={showOrderTestMenu ? "var(--genseq-green)" : null}
            text="Order A Test"
            id="order-test-navigation"
            onClick={(e) => {
              e.preventDefault();
              setShowOrderTestMenu((prevState) => !prevState)
            }}
            open={showOrderTestMenu}
            maxWidth="200px"
          >
            <ChevronDown />
          </Button>
          {showOrderTestMenu && (
            <SmallMenu
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{
                duration: 1,
                ease: [0.04, 0.62, 0.23, 0.98],
                opacity: { duration: 0.8 },
              }}
              style={{ top: "4.125rem" }}
            >
              <h5>Clinical</h5>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowRequestAccountFormDialog(true);
                  setDropdownOpen(false);
                }}
              >
                Account Registration
              </button>
              <Link to="/clinical-portal" onClick={() => setDropdownOpen(false)}>Login</Link>
              <h5>Biopharma/ Research</h5>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setShowRequestFormDialog(true);
                  setDropdownOpen(false);
                }}
              >
                Request A Quote
              </button>
            </SmallMenu>
          )}
        </div>
        <Social>
          <a
            href="https://www.linkedin.com/company/genseq"
            target="_blank"
            aria-label="Company LinkedIn Profile"
          >
            <LinkedinIcon />
          </a>
        </Social>
        <Utility>
          <p>©2023 Genseq All Rights Reserved.</p>
          <div>
            <div
              type="button"
              onClick={() => {
                navigate("/terms-and-conditions");
                setDropdownOpen(false);
              }}
            >
              Terms & Conditions
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/privacy-policy");
                setDropdownOpen(false);
              }}
            >
              Privacy Policy
            </div>
            <div
              type="button"
              onClick={() => {
                navigate("/cookie-policy");
                setDropdownOpen(false);
              }}
            >
              Cookie Policy
            </div>
          </div>
        </Utility>
      </Footer>
    </Container>
  );
};

const Container = styled(motion.div)`
  background: var(--genseq-blue);
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 4rem);
  max-height: calc(100vh - 4rem);
  overflow: hidden;
  z-index: 10;
`;

const Links = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  scroll-margin: 0;
  top: 0;
  overflow-y: scroll;
  margin: auto 0;
  &::-webkit-scrollbar {
    width: 0.5rem;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 0.625rem;
  }
`;

const Anchor = styled.button`
  color: ${({ open }) => (open ? "var(--genseq-green)" : "#FFFFFF")};
  cursor: pointer;
  font-size: 2rem;
  line-height: 2.875rem;
  padding: 0.5rem 1.25rem;
  transition: all 0.3 ease-in-out;
`;

const Footer = styled.div`
  background: var(--genseq-blue);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
  width: 100%;
  padding: 2rem 1.25rem;
  button {
    margin: 0 auto 1.25rem auto;
  }
  &:before {
    background: #e5e5e5;
    content: "";
    height: 1px;
    left: 1.25rem;
    top: 0;
    width: calc(100% - 2.5rem);
    position: absolute;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.125rem;
  a {
    height: 24px;
    width: 24px;
    svg {
      height: 100%;
      width: 100%;
      circle {
        fill: #ffffff;
      }
      path {
        fill: var(--genseq-blue);
      }
    }
  }
`;

const Utility = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 0.25rem;
  div {
    display: flex;
    gap: 1.125rem;
    div {
      color: #ffffff;
      cursor: pointer;
      height: max-content;
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-thickness: 1px;
      &:hover {
        font-weight: 400;
        margin-top: -1px;
      }
    }
  }
  a {
    color: #ffffff;
    height: max-content;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
    &:hover {
      font-weight: 400;
      margin-top: -1px;
    }
  }
`;

const SmallMenu = styled(motion.div)`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1.5rem 1.25rem;
  h5 {
    text-align: left;
    font-size: 1rem;
  }
  a {
    color: #ffffff;
    font-weight: 300;
    font-size: 1rem;
    margin-left: 1.5rem;
    text-align: left;
    text-decoration: none;
    h5 {
      margin-left: -1.5rem;
    }
  }
  button {
    cursor: pointer;
    font-weight: 300;
    font-size: 1rem;
    margin-left: 1.5rem;
    margin-bottom: 0;
    text-align: left;
    text-decoration: none;
    h5 {
      margin-left: -1.5rem;
    }
  }
`;

export default DropdownNavigation;
