import * as React from "react";
const SvgPdfIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 108 108"
    {...props}
  >
    <path
      fill="#645FE6"
      fillOpacity={0.3}
      d="M101.25 60.75V54H81v33.75h6.75v-13.5h10.125V67.5H87.75v-6.75h13.5Zm-37.125 27h-13.5V54h13.5A10.135 10.135 0 0 1 74.25 64.125v13.5A10.135 10.135 0 0 1 64.125 87.75ZM57.375 81h6.75a3.375 3.375 0 0 0 3.375-3.375v-13.5a3.375 3.375 0 0 0-3.375-3.375h-6.75V81Zm-20.25-27H20.25v33.75H27V77.625h10.125a6.76 6.76 0 0 0 6.75-6.75V60.75a6.75 6.75 0 0 0-6.75-6.75ZM27 70.875V60.75h10.125l.003 10.125H27Z"
    />
    <path
      fill="#645FE6"
      fillOpacity={0.3}
      d="M74.25 47.25v-13.5a3.071 3.071 0 0 0-1.013-2.362L49.614 7.763A3.037 3.037 0 0 0 47.25 6.75H13.5a6.77 6.77 0 0 0-6.75 6.75v81a6.75 6.75 0 0 0 6.75 6.75h54V94.5h-54v-81h27v20.25a6.77 6.77 0 0 0 6.75 6.75H67.5v6.75h6.75Zm-27-13.5v-18.9l18.9 18.9h-18.9Z"
    />
  </svg>
);
export default SvgPdfIcon;
