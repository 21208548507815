import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BannerGridRight } from "../../../assets/svgs";

const OurCredentials = ({ credentialImages }) => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            relativeDirectory: { eq: "assets/images/our-team/credentials" }
          }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"],
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], {
      ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
    });
  };

  const topY = useParallax(scrollYProgress, 25);
  const bottomY = useParallax(scrollYProgress, -15);

  return (
    <Container id="our-credentials">
      <div>
        <Title>Our Credentials</Title>
        <p>
          At Genseq, we recognise the importance of quality in all aspects of
          data generation and analysis.
          <br />
          <br />
          Our laboratory participates in various external quality assurance
          schemes and proudly holds accreditation from the College of American
          Pathologists (CAP) and ISO 15189 accreditation from the Irish National
          Accreditation Board (INAB).
        </p>
      </div>
      <Images ref={containerRef}>
        <TopImage style={{ y: topY }}>
          <GatsbyImage
            image={getImage(images.edges[0].node.childImageSharp)}
            alt={images.edges[0].node.name}
          />
        </TopImage>
        <SVGContainerRight>
          <BannerGridRight />
        </SVGContainerRight>
        <BottomImage style={{ y: bottomY }}>
          <GatsbyImage
            image={getImage(images.edges[1].node.childImageSharp)}
            alt={images.edges[1].node.name}
          />
          <GatsbyImage
            image={getImage(images.edges[2].node.childImageSharp)}
            alt={images.edges[2].node.name}
          />
        </BottomImage>
      </Images>
    </Container>
  );
};

const Container = styled.section`
  grid-column: 1 / 7;
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / 13;
    padding: 4rem 0;
    gap: 6rem;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 13;
  }
`;

const Title = styled.h2`
  color: var(--genseq-purple);
  font-size: 1.875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  position: relative;
`;

const TopImage = styled(motion.div)`
  grid-column: 1 / 4;
  z-index: 1;
  div {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    bord-radius: 0.25rem;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 4;
  }
`;

const BottomImage = styled(motion.div)`
  grid-column: 3 / 7;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  z-index: 3;
  top: -3rem;
  div {
    border-radius: 0.25rem;
    &:nth-of-type(1) {
      grid-column: 1 / 5;
      z-index: 3;
    }
    &:nth-of-type(2) {
      box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
      grid-column: 5 / 7;
      margin-left: 0.75rem;
      z-index: 3;
    }
  }
  @media screen and (min-width: 1024px) {
    top: -8rem;
  }
`;

const SVGContainerRight = styled.div`
  right: -1.25rem;
  top: 0.5rem;
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  svg {
    width: 75%;
    height: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 1024px) {
    top: 0;
    right: 0;
    svg {
      width: 60%;
    }
  }
`;

export default OurCredentials;
