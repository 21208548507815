import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BannerGridRight } from "../../../assets/svgs";

const Sustainability = () => {
  const { myGreenLab } = useStaticQuery(
    graphql`
      query {
        myGreenLab: allFile(
          filter: {
            relativePath: { eq: "assets/images/our-team/my-green-lab.png" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 160)
              }
            }
          }
        }
      }
    `
  );

  return (
    <Container id="sustainability">
      <div>
        <Title>Sustainability</Title>
        <p>
          At Genseq, we recognise the significance of moving towards a more
          sustainable future, and it's crucial for us to play our part. We've
          initiated our sustainability efforts by joining the My Green Lab
          Ambassador program.
          <br />
          <br />
          Through this, we aim to enhance the sustainability of our laboratory,
          with the goal of making a positive impact on the environment.
        </p>
      </div>
      <Images>
        <ImageContainer>
        <GatsbyImage image={getImage(myGreenLab.edges[0].node)} alt="my green lab ambassador" />
        </ImageContainer>
        <SVGContainerRight>
          <BannerGridRight />
        </SVGContainerRight>
      </Images>
    </Container>
  );
};

const Container = styled.section`
  grid-column: 1 / 7;
  padding: 3rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column: 2 / 13;
    padding: 0 0 6rem 0;
    gap: 6rem;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 13;
  }
`;

const Title = styled.h2`
  color: var(--genseq-purple);
  font-size: 1.875rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  @media screen and (min-width: 1024px) {
    font-size: 2.25rem;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  position: relative;
`;

const ImageContainer = styled.div`
  grid-column: 1/3;
  align-self: center;
  @media screen and (min-width: 1024px) {
    justify-self: end;
  }
`;

const SVGContainerRight = styled.div`
  right: -1.25rem;
  top: 0.5rem;
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  svg {
    width: 75%;
    height: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 1024px) {
    top: 0;
    right: 0;
    svg {
      width: 60%;
    }
  }
`;

export default Sustainability;
