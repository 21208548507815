import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { Link } from "gatsby";
import { useWindowSize } from "../../../../hooks";
import { serviceIconMapper } from "../../../../utils";

const Menu = ({ menuItems }) => {
  const { windowWidth } = useWindowSize();
  const [hovered, setHovered] = useState(null);
  const [colors, setColors] = useState([
    "#645FE6",
    "#1AE299",
    "#FF7D50",
    "#36CBFF",
  ]);

  useEffect(() => {
    if (menuItems.length > 4) {
      let newColors = [];
      const amount = Math.ceil(menuItems.length / 4);
      for (let index = 0; index < amount; index++) {
        newColors.push(...["#645FE6", "#1AE299", "#FF7D50", "#36CBFF"]);
      }
      setColors(newColors);
    }
  }, [menuItems]);

  return (
    <Container>
      {menuItems
        .map(({ node }, index) => {
          console.log(node.item);
          const SVG = serviceIconMapper[node.slug.current];
          return (
            <MenuItem
              key={index}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              color={hovered === index ? colors[index] : "transparent"}
              to={`/services/biopharma-and-research/${node.slug.current}`}
            >
              <SVGContainer position={index}>
                <SVG
                  color={
                    hovered === index || windowWidth < 1024
                      ? colors[index]
                      : "#061C57"
                  }
                />
              </SVGContainer>
              <h5>
                <Markup content={node.title} noHtml={windowWidth >= 1024} />
              </h5>
              {windowWidth >= 1024 && <p>See More</p>}
            </MenuItem>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column: 1 / 7;
  gap: 3rem;
  padding: 0 1.25rem;
  @media screen and (min-width: 780px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column: 2 / 12;
    padding: unset;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const SVGContainer = styled.div`
  height: 48px;
  width: auto;
  svg {
    height: 100%;
    width: auto;
    max-width: 48px;
  }
`;

const MenuItem = styled(Link)`
  align-items: start;
  color: var(--genseq-blue);
  cursor: pointer;
  display: grid;
  font-size: 1.25rem;
  grid-template-rows: 50px 1fr;
  gap: 0.875rem;
  justify-content: center;
  text-align: center;
  text-decoration: none !important;
  p {
    color: ${({ color }) => color};
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: underline;
    transition: all 0.3s ease-out;
  }
  @media screen and (min-width: 780px) {
    justify-content: start;
    text-align: left;
  }
`;

export default Menu;
