import * as React from "react";
const SvgNoResult = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#no-result_svg__a)"
    >
      <path stroke="#061C57" d="M.719.719v57.923H59.28" />
      <path
        stroke="#D9D9D9"
        d="M7.133 52.745h44.789M7.133 52.583V7.798M16.091 52.583V7.798M25.05 52.583V7.798M34.005 52.583V7.798M42.963 52.583V7.798M51.922 52.583V7.798"
      />
    </g>
    <defs>
      <clipPath id="no-result_svg__a">
        <path fill="#fff" d="M0 0h60v59.36H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgNoResult;
