import * as React from "react";
const SvgConversation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 53 48"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M45.178 7.653c2.11 0 3.822 2.052 3.822 4.573v17.829c0 2.52-1.712 4.564-3.822 4.564h-5.266v9.025c0 .636-.814.909-1.209.406l-7.402-9.431H22.73"
    />
    <path
      stroke="#1AE299"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M41.564 7.943v16.584c0 2.346-1.595 4.252-3.563 4.252H20.467l-6.803 8.664c-.395.503-1.21.226-1.21-.406V28.78H7.557c-1.967 0-3.562-1.907-3.562-4.253V7.943C4 5.597 5.595 3.691 7.562 3.691h30.444c1.967 0 3.562 1.906 3.562 4.252h-.005ZM10.145 9.794h25.273M10.145 15.356h25.273M10.145 20.923h18.522"
    />
  </svg>
);
export default SvgConversation;
