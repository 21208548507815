import * as React from "react";
const SvgDyslipidaemiaPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 52"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.234 26.183c.154-.12.309-.241.459-.366M34.534 34.833c.154-.12.309-.241.459-.366M39.908 28.333a5.006 5.006 0 0 0 5.004-5.004 5.006 5.006 0 0 0-5.004-5.004 5.006 5.006 0 0 0-5.005 5.004 5.006 5.006 0 0 0 5.005 5.004ZM24.582 45.23a5.006 5.006 0 0 0 5.005-5.005 5.006 5.006 0 0 0-5.005-5.004 5.006 5.006 0 0 0-5.004 5.004 5.006 5.006 0 0 0 5.004 5.004ZM27.612 18.942a3.03 3.03 0 1 0 0-6.059 3.03 3.03 0 0 0 0 6.059ZM16.484 8.787c1.433 2.613 1.012 5.813-.95 8.055A13.856 13.856 0 0 0 12.092 26c0 3.042.975 5.854 2.633 8.142 1.659 2.287 1.6 5.558-.316 7.741"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M39.28 40.592c.154-.121.308-.242.458-.367"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26 51c13.808 0 25-11.192 25-25S39.808 1 26 1 1 12.192 1 26s11.192 25 25 25Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M41.583 38a19.573 19.573 0 0 0 4.083-12c0-10.862-8.804-19.667-19.666-19.667C15.137 6.333 6.333 15.138 6.333 26c0 10.862 8.804 19.667 19.667 19.667 4.1 0 7.908-1.255 11.062-3.404"
    />
  </svg>
);
export default SvgDyslipidaemiaPanel;
