import * as React from "react";
const SvgTransthyretinAmyloidosisTtr = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 51"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.153 48.458c10.554-8.106 13.815-28.354-2.355-35.33"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.786 38.624c6.806-1.632 6.806-14.383-1.16-17.822M17.852 5.7c-.588-.854-1.228-1.359-2.088-1.821a.453.453 0 0 1-.241-.45l.212-1.745a.453.453 0 0 0-.394-.5l-1.495-.18a.455.455 0 0 0-.504.39l-.148 1.236a.45.45 0 0 1-.462.396c-.428-.017-.86 0-1.292.067a.446.446 0 0 1-.491-.324l-.386-1.37a.45.45 0 0 0-.554-.312l-1.453.4a.446.446 0 0 0-.313.55l.482 1.716a.46.46 0 0 1-.182.492 7.01 7.01 0 0 0-.897.752.457.457 0 0 1-.576.042L5.583 3.98a.46.46 0 0 0-.631.102l-.88 1.215a.452.452 0 0 0 .1.626L5.85 7.121a.447.447 0 0 1 .16.512 6.626 6.626 0 0 0-.088 3.898c.66 2.363 2.52 4.078 4.739 4.68"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.107 20.76c3.372.176 5.773-1.708 6.556-8.03C15.76 3.874 26.827 2.226 26.827 2.226a.525.525 0 0 1 .677.294L29.89 8.6a.519.519 0 0 1-.297.672c-3.892 1.535-9.728 3.919-7.513 14.867M22.088 28.239c0 6.924-9.012 6.924.711 20.761C19.752 48.332 18 48 15 46M4.715 6.406l-3.23 3.56a.18.18 0 0 0 0 .257l1.19 1.173c4.145 5.975-3.3 9.04-1.34 15.825.338 1.167.53 1.945 1.165 3.279"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.83 28.443h-.002c-.912-.245-1.863.345-2.123 1.317l-1.05 3.916c-.26.972.269 1.958 1.18 2.202h.002c.912.245 1.863-.345 2.123-1.317l1.05-3.916c.26-.972-.268-1.958-1.18-2.202ZM14.272 30.17l-.001-.001c-.913-.245-1.863.345-2.124 1.317l-1.049 3.916c-.26.972.268 1.958 1.18 2.203h.002c.912.244 1.863-.345 2.123-1.317l1.05-3.916c.26-.972-.269-1.958-1.181-2.203ZM5.412 37.467H5.41c-.912-.245-1.863.345-2.123 1.316L1.472 45.56c-.26.972.268 1.958 1.18 2.202h.001c.912.245 1.863-.345 2.123-1.317l1.816-6.775c.26-.972-.268-1.958-1.18-2.202ZM11.854 39.193h-.001c-.912-.245-1.863.345-2.123 1.317l-1.816 6.775c-.26.972.268 1.958 1.18 2.202h.002c.912.245 1.863-.345 2.123-1.317l1.815-6.775c.26-.972-.268-1.958-1.18-2.202Z"
    />
  </svg>
);
export default SvgTransthyretinAmyloidosisTtr;
