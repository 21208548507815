import * as React from "react";
const SvgHereditaryCancer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 57 47"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m46.45 31.262 1.543-1.87C51.461 25.253 48.481 19 43.041 19c-5.44 0-8.42 6.253-4.952 10.392L52.004 46 56 42.518 41.516 25.454c-1.5-1.787-1.791-3.967-1.204-5.87"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M43.044 35.302 34.077 46 30 41.975l9.746-10.607M40.344 23.354a2.647 2.647 0 0 1 3.298.332 2.565 2.565 0 0 1 0 3.664M41 13.68C37.398 5.255 28.56-.088 19.087 1.188 7.672 2.725-.34 13.288 1.187 24.784 2.713 36.281 13.204 44.35 24.616 42.812"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.58 7c-7.847 10.456 6.46 20.545-1.384 31"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.889 17.089c-7.848 10.455-22.156.367-30 10.822M35.186 18.962l-9.587-6.76M30.086 21.349l-4.796-3.384M9.17 26.533l9.589 6.76M13.422 23.545l4.792 3.38"
    />
  </svg>
);
export default SvgHereditaryCancer;
