import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  HealthcareProfessional,
  Patient,
  Close,
} from "../../../../assets/svgs";

const RedirectDialog = ({ onDismiss }) => {
  return (
    <Container>
      <CloseButton onClick={onDismiss}>
        <Close />
      </CloseButton>
      <p>
        Before entering, please confirm which PrenatalSEQ
        <br />
        information you're interested in
      </p>
      <Subcategories>
        <Subcategory
          onClick={() =>
            navigate(
              "/services/clinical/prenatal-seq/healthcare-professional-resources"
            )
          }
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.025 }}
        >
          <HealthcareProfessional />
          <p>
            Healthcare Professional
            <br />
            Resources
          </p>
          <SeeMore color="var(--genseq-purple)">SEE MORE</SeeMore>
        </Subcategory>
        <Subcategory
          onClick={() =>
            navigate("/services/clinical/prenatal-seq/patient-resources")
          }
          whileTap={{ scale: 0.975 }}
          whileHover={{ scale: 1.005 }}
        >
          <Patient />
          <p>Patient Resources</p>
          <SeeMore color="var(--genseq-green)">SEE MORE</SeeMore>
        </Subcategory>
      </Subcategories>
    </Container>
  );
};

const Subcategories = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  margin: 2rem auto;
  max-width: 580px;
  width: 100%;
`;

const Subcategory = styled(motion.button)`
  align-items: center;
  color: var(--genseq-blue);
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1.25rem;
  &:nth-of-type(2) {
    p {
      margin-top: 0.5rem;
    }
  }
  svg {
    width: 48px;
    height: auto;
  }
`;

const Container = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1.25rem;
  text-align: center;
  padding: 3rem 1.25rem 1.5rem 1.25rem;
  width: 100%;
  position: relative;
  p {
    max-width: 700px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1024px) {
    min-width: 960px;
    padding: 3rem 1.25rem;
  }
`;

const SeeMore = styled.div`
  color: ${({ color }) => color};
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  width: max-content;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 1px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  height: 12px;
  width: 12px;
  svg {
    height: 100%;
    width: auto;
  }
`;

export default RedirectDialog;
