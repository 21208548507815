import * as React from "react";
const SvgPatient = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 52 52"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.469 9.797c0 5.469 11.383 10.514 15.372 10.37"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.223 36.555c4.762 0 7.916-1.764 7.916-1.764s-2.072-10.868-3.207-19.538c-.639-4.892-2.672-8.61-5.602-11.02a13.642 13.642 0 0 0-2.516-1.639C30.733 1.542 28.356 1 25.8 1c-7.79 0-13.93 5.045-15.135 14.257-1.13 8.67-3.208 19.538-3.208 19.538s3.547 1.764 8.31 1.764"
    />
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M36.226 20.154v5.674c0 5.333-4.378 9.618-10.025 9.618S15.77 31.16 15.77 25.828V18.93c0-2.188 3.395-4.066 7.192-5.586M19.176 32.912v6.98M32.816 33.1v6.792M51 51l-.715-2.359c-.625-2.061-1.894-3.118-5.066-4.372-3.1-1.223-12.402-4.385-12.402-4.385S29.788 48.771 26 48.771c-3.788 0-6.817-8.888-6.817-8.888S9.877 43.042 6.78 44.268c-3.176 1.254-4.44 2.306-5.066 4.372L1 51"
    />
  </svg>
);
export default SvgPatient;
