import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PlayButton } from "../../../assets/svgs";
import { CarouselArrow } from "../../../assets/animated-svgs";
import { useWindowSize } from "../../../hooks";

const VideoCarousel = ({
  videos,
  videoPlaying,
  setVideoPlaying,
  videoThumbnail,
}) => {
  const [slide, setSlide] = useState(0);
  const { windowWidth } = useWindowSize();
  return (
    <Carousel>
      <Back
        onClick={() => {
          if (slide > 0) {
            setSlide(slide - 1);
          }
        }}
        disable={slide === 0}
      >
        <CarouselArrow highlighted={slide > 0} />
      </Back>
      <Track>
        <Slides distance={`-${slide * 236}px`}>
          {videos.map((video, index) => (
            <Slide key={`video-${index}`}>
              <VideoThumbnail>
                <GatsbyImage image={getImage(videoThumbnail)} />
                <Overlay
                  onClick={() => setVideoPlaying(video.id)}
                  whileHover={{
                    scale: 1.075,
                    transition: { duration: 0.3 },
                  }}
                  whileTap={{ scale: 0.9 }}
                >
                  {videoPlaying !== video.id ? (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.9 }}
                      animate={{ opacity: 1, scale: 1 }}
                    >
                      <PlayButton />
                    </motion.div>
                  ) : (
                    <motion.div>
                      <Tag>Now Playing</Tag>
                    </motion.div>
                  )}
                </Overlay>
              </VideoThumbnail>
              <p>{video.title}</p>
            </Slide>
          ))}
        </Slides>
      </Track>
      <Next
        onClick={() => {
          if (windowWidth >= 1024 && slide < 2) {
            setSlide(slide + 1);
          } else if (windowWidth >= 720 && windowWidth < 1024 && slide < 3) {
            setSlide(slide + 1);
          } else if (windowWidth < 720 && slide < 4) {
            setSlide(slide + 1);
          }
        }}
      >
        <CarouselArrow
          highlighted={
            (windowWidth >= 1024 && slide < 2) ||
            (windowWidth >= 720 && windowWidth < 1024 && slide < 3) ||
            (windowWidth < 720 && slide < 4)
          }
        />
      </Next>
    </Carousel>
  );
};

const Carousel = styled.div`
  position: relative;
  margin: 0 auto;
  width: 216px;
  @media screen and (min-width: 720px) {
    width: 452px;
  }
  @media screen and (min-width: 1024px) {
    width: 688px;
  }
`;

const VideoThumbnail = styled.div`
  width: 216px;
  height: 121px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
`;

const Overlay = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  > div {
    position: relative;
    height: 100%;
    width: 100%;
    svg {
      height: 42px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 42px;
    }
  }
`;

const Track = styled.div`
  overflow: hidden;
  position: relative;
`;

const Slide = styled.div`
  width: 216px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
`;

const Slides = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
  gap: 1.25rem;
  margin-left: ${({ distance }) => distance};
  transition: all 0.3s ease-in-out;
`;

const Tag = styled.div`
  align-items: center;
  color: #ffffff;
  background: var(--genseq-purple);
  border-radius: 50px;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  height: 40px;
  left: 50%;
  position: absolute;
  text-transform: uppercase;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 160px;
`;

const Direction = styled.button`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: center;
  top: 0;
  position: absolute;
  height: 100%;
  margin-top: 50px;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const Back = styled(Direction)`
  left: -2.25rem;
`;

const Next = styled(Direction)`
  right: -1.75rem;
  svg {
    transform: rotate(180deg);
  }
`;

export default VideoCarousel;
