import * as React from "react";
const SvgExseq = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 606 144"
    {...props}
  >
    <path
      fill="url(#exseq_svg__a)"
      d="M253.003 4.744c-6.946 3.04-12.372 7.475-16.278 13.303-3.908 5.83-5.861 12.776-5.861 20.837 0 6.697 1.119 12.25 3.351 16.65 2.23 4.405 5.176 8.065 8.834 10.977a54.6 54.6 0 0 0 12.003 7.257 153.905 153.905 0 0 0 13.116 5.115c4.399 1.489 8.433 3.04 12.092 4.653 3.656 1.612 6.604 3.533 8.836 5.765 2.235 2.234 3.349 5.15 3.349 8.746 0 3.97-1.706 7.069-5.116 9.301-3.413 2.233-8.217 3.349-14.417 3.349-6.825 0-12.808-1.33-17.955-4-5.148-2.664-9.83-6.418-14.048-11.256l-18.974 18.791c6.448 7.196 13.548 12.559 21.301 16.095 7.751 3.533 17.023 5.301 27.815 5.301 15.75 0 28.093-3.564 37.024-10.697 8.93-7.131 13.394-16.962 13.394-29.49 0-6.82-1.117-12.558-3.349-17.208-2.232-4.652-5.179-8.494-8.836-11.534a53.58 53.58 0 0 0-12.001-7.536 138.51 138.51 0 0 0-13.116-5.21c-4.404-1.488-8.436-2.976-12.094-4.464-3.66-1.49-6.604-3.256-8.836-5.302-2.234-2.048-3.348-4.744-3.348-8.095 0-3.595 1.488-6.353 4.464-8.277 2.975-1.922 7.006-2.886 12.092-2.886 5.084 0 9.643.996 13.676 2.978 4.028 1.986 8.028 5.024 11.999 9.117l18.976-18.792c-4.961-5.581-11.255-9.983-18.881-13.21C294.585 1.8 286.18.184 277.003.184c-9.055 0-17.054 1.522-24 4.559Z"
    />
    <path
      fill="url(#exseq_svg__b)"
      d="M347.235 27.35h93.397V2.231h-93.397v25.117Z"
    />
    <path
      fill="url(#exseq_svg__c)"
      d="M361.188 78.699h65.489V54.324h-65.489V78.7Z"
    />
    <path
      fill="url(#exseq_svg__d)"
      d="M347.235 133.582h94.325v-25.303h-94.325v25.303Z"
    />
    <path
      fill="url(#exseq_svg__e)"
      d="M529.205 0c-9.798 0-18.885 1.707-27.254 5.116-8.374 3.412-15.694 8.187-21.955 14.325-6.265 6.139-11.135 13.302-14.604 21.49-3.472 8.184-5.209 17.116-5.209 26.79 0 9.554 1.768 18.45 5.301 26.699 3.536 8.247 8.433 15.472 14.699 21.673 6.262 6.204 13.61 11.042 22.046 14.511 8.434 3.471 17.547 5.209 27.35 5.209 9.794 0 18.852-1.738 27.162-5.209a70.493 70.493 0 0 0 11.779-6.267L588.184 144l16.931-16.931-19.299-19.297a66.59 66.59 0 0 0 7.392-13.168c3.469-8.247 5.207-17.145 5.207-26.696 0-9.676-1.738-18.633-5.207-26.885-3.476-8.247-8.347-15.443-14.608-21.582-6.265-6.138-13.581-10.913-21.951-14.325C548.275 1.706 539.127 0 529.205 0ZM513.3 106.232c-4.777-1.981-8.903-4.805-12.372-8.465-3.476-3.656-6.141-8.028-7.999-13.115-1.862-5.085-2.793-10.728-2.793-16.93 0-8.31 1.643-15.535 4.929-21.675 3.287-6.14 7.846-10.914 13.676-14.326 5.828-3.409 12.651-5.116 20.464-5.116 5.954 0 11.316.962 16.094 2.883 4.774 1.924 8.9 4.716 12.372 8.373 3.469 3.66 6.14 8 8.001 13.023 1.861 5.024 2.788 10.636 2.788 16.837 0 7.058-1.228 13.398-3.671 19.026l-21.073-21.074-16.931 16.931 21.99 21.988c-5.599 3.065-12.115 4.617-19.57 4.617-5.832 0-11.136-.992-15.905-2.977Z"
    />
    <mask
      id="exseq_svg__f"
      width={606}
      height={144}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M0 0h605.113v144H0V0Z" />
    </mask>
    <g fill="#061C57" mask="url(#exseq_svg__f)">
      <path d="M29.21 78.698h58.605V54.326H29.209V27.348h64.187V2.232H0v131.35h94.327v-25.303H29.209V78.698ZM200.781 133.582l-31.952-46.636 30.091-43.783h-30.883l-15.233 24.51-15.094-24.51h-33.302l30.706 44.37-32.38 46.049h31.07l17.349-26.972 16.697 26.972h32.931Z" />
    </g>
    <defs>
      <linearGradient
        id="exseq_svg__a"
        x1={189.84}
        x2={599.017}
        y1={95.792}
        y2={-37.978}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D50" />
        <stop offset={0.523} stopColor="#645FE6" />
        <stop offset={1} stopColor="#1AE299" />
      </linearGradient>
      <linearGradient
        id="exseq_svg__b"
        x1={191.624}
        x2={600.801}
        y1={80.93}
        y2={-52.839}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D50" />
        <stop offset={0.523} stopColor="#645FE6" />
        <stop offset={1} stopColor="#1AE299" />
      </linearGradient>
      <linearGradient
        id="exseq_svg__c"
        x1={206.899}
        x2={616.076}
        y1={127.657}
        y2={-6.112}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D50" />
        <stop offset={0.523} stopColor="#645FE6" />
        <stop offset={1} stopColor="#1AE299" />
      </linearGradient>
      <linearGradient
        id="exseq_svg__d"
        x1={223.017}
        x2={632.194}
        y1={176.958}
        y2={43.189}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D50" />
        <stop offset={0.523} stopColor="#645FE6" />
        <stop offset={1} stopColor="#1AE299" />
      </linearGradient>
      <linearGradient
        id="exseq_svg__e"
        x1={229.844}
        x2={639.021}
        y1={177.524}
        y2={43.755}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7D50" />
        <stop offset={0.523} stopColor="#645FE6" />
        <stop offset={1} stopColor="#1AE299" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgExseq;
