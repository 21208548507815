import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Label,
  Input,
  FormCheck,
  FormCheckbox,
  Back,
} from "../../FormComponents";
import { Button } from "../../../../../components";
import { ArrowBack } from "../../../../../assets/svgs";

const ShippingInformation = ({
  formValues,
  handleInputChange,
  shippingAddress,
  setShippingAddress,
  setFormPage,
}) => {
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleAddressInputChange = (e) => {
    e.persist();
    const { name, type, checked, value } = e.target;

    setShippingAddress((currentValues) => ({
      ...currentValues,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  useEffect(() => {
    setMessage("");
    if (
      shippingAddress.address1.length > 1 &&
      shippingAddress.county.length > 1 &&
      shippingAddress.postcode.length > 1 &&
      shippingAddress.country.length > 1
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [shippingAddress]);

  const handleNextClick = (e) => {
    e.preventDefault();
    setFormPage(3);
  };

  return (
    <Fields>
      <Back
      type="button"
        onClick={() => {
          setFormPage(1);
        }}
      >
        <ArrowBack />
      </Back>
      <p>Shipping Address</p>
      <small style={{ marginTop: '0.5rem' }}>
          Fields marked with an <span>*</span> are mandatory
      </small>
      <Label>
        <p>
          Address 1<span>*</span>
        </p>
        <Input
          type="text"
          id="address1"
          name="address1"
          value={shippingAddress.address1}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>Address 2</p>
        <Input
          type="text"
          id="address2"
          name="address2"
          value={shippingAddress.address2}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
        />
      </Label>
      <Label>
        <p>Address 3</p>
        <Input
          type="text"
          id="address3"
          name="address3"
          value={shippingAddress.address3}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
        />
      </Label>
      <Label>
        <p>
          County<span>*</span>
        </p>
        <Input
          type="text"
          id="county"
          name="county"
          value={shippingAddress.county}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Postcode/Eircode<span>*</span>
        </p>
        <Input
          type="text"
          id="postcode"
          name="postcode"
          value={shippingAddress.postcode}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <p>
          Country<span>*</span>
        </p>
        <Input
          type="text"
          id="country"
          name="country"
          value={shippingAddress.country}
          onChange={(e) => {
            handleAddressInputChange(e);
          }}
          required
        />
      </Label>
      <Label>
        <FormCheck>
          <FormCheckbox
            type="checkbox"
            id="sameAsShipping"
            name="sameAsShipping"
            onChange={(e) => {
              handleInputChange(e);
            }}
            checked={formValues.sameAsShipping}
            value={formValues.sameAsShipping}
          />
          <p>Use shipping address as billing address</p>
        </FormCheck>
      </Label>
      <Button
        type="button"
        solid="var(--genseq-blue)"
        maxWidth="220px"
        text="Next"
        disabled={disabled}
        onClick={handleNextClick}
      />
    </Fields>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;

export default ShippingInformation;
