import * as React from "react";
const SvgLowProbability = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#low-probability_svg__a)"
    >
      <path stroke="#061C57" d="M.719.719V59H59.28" />
      <path
        stroke="#061C57"
        d="M33.121 53.108h6.889V29.319H33.12v23.789ZM45.856 53.104h6.889V37.602h-6.889v15.502ZM20.54 53.104h6.889v-31H20.54v31ZM7.952 53.108h6.889V16.397H7.952v36.71Z"
      />
      <path
        stroke="#1AE299"
        d="m48.598 26.444 5.714-.675-3.443-4.61M7.327 4.743 54.308 25.77"
      />
    </g>
    <defs>
      <clipPath id="low-probability_svg__a">
        <path fill="#fff" d="M0 0h60v59.72H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLowProbability;
