import * as React from "react";
const SvgHereditaryColorectalCancerAndPolyposisPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 55 50"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.756 49H2.452C1.492 49 1 48.46 1 47.405V36.32c0-1.053.492-1.595 1.452-1.595H22.09c.963 0 1.451.539 1.451 1.595v11.085c0 1.053-.492 1.595-1.451 1.595H10.664M4.695 43.181H7.86M4.695 45.713H7.86M4.695 38.219H7.86M10.677 41.932h3.166M10.676 45.713h3.166M16.666 43.926h3.166M16.666 41.23h3.166M10.676 38.219h3.166M16.666 38.219h3.166"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.352 48.659c-2.65-3.7-.87-12.527-.87-12.527h.003a5.705 5.705 0 0 1-1.133-3.405c0-3.228 2.694-5.844 6.016-5.844a6.1 6.1 0 0 1 3.883 1.383 6.069 6.069 0 0 1 4.713-2.22c.086 0 .17.01.257.013a5.681 5.681 0 0 1-.257-1.683c0-2.207 1.261-4.127 3.122-5.122-2.758-.531-4.84-2.894-4.84-5.73 0-.777.159-1.517.443-2.194a6.185 6.185 0 0 1-.621-.59c-.644 2.556-3.01 4.454-5.839 4.454-2.595 0-4.8-1.598-5.644-3.834a6.07 6.07 0 0 1-4.672 2.163 6.102 6.102 0 0 1-3.74-1.27c-.031.01-.064.02-.095.032.253.646.397 1.342.397 2.073 0 1.863-.902 3.521-2.303 4.591 1.4 1.07 2.303 2.726 2.303 4.592 0 3.227-2.694 5.843-6.017 5.843-3.323 0-6.017-2.616-6.017-5.843 0-1.864.903-3.522 2.304-4.592-1.4-1.07-2.304-2.726-2.304-4.591 0-2.478 1.592-4.592 3.836-5.442a5.664 5.664 0 0 1-.397-2.072C11.88 3.616 14.573 1 17.896 1c1.414 0 2.712.478 3.74 1.271a6.152 6.152 0 0 1 2.277-.437c2.596 0 4.8 1.598 5.645 3.834a6.07 6.07 0 0 1 4.671-2.163c1.78 0 3.375.754 4.477 1.948C39.35 2.898 41.716 1 44.545 1c3.323 0 6.017 2.616 6.017 5.844 0 .776-.159 1.516-.443 2.193 1.322 1.072 2.163 2.682 2.163 4.485 0 2.207-1.261 4.128-3.122 5.122 2.759.531 4.84 2.895 4.84 5.73 0 3.228-2.694 5.845-6.017 5.845-.086 0-.17-.01-.256-.013a5.68 5.68 0 0 1 .256 1.683c0 3.227-2.694 5.844-6.017 5.844a6.1 6.1 0 0 1-3.882-1.383 6.032 6.032 0 0 1-1.718 1.43l.003.008s1.949 8.047-.851 11.212"
    />
  </svg>
);
export default SvgHereditaryColorectalCancerAndPolyposisPanel;
