import * as React from "react";
const SvgDownloadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 15 14"
    {...props}
  >
    <path
      fill="#645FE6"
      d="M14.25 6.496a.75.75 0 0 0-.75.75v4.5a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 1 0-1.5 0v4.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-4.5a.75.75 0 0 0-.75-.75Z"
    />
    <path
      fill="#645FE6"
      d="m8.25 7.942 1.718-1.725a.753.753 0 0 1 1.065 1.065l-3 3a.751.751 0 0 1-.247.158.75.75 0 0 1-.57 0 .751.751 0 0 1-.248-.157l-3-3a.75.75 0 0 1 .244-1.23.75.75 0 0 1 .821.164l1.718 1.725V.75a.75.75 0 0 1 1.5 0v7.192Z"
    />
  </svg>
);
export default SvgDownloadIcon;
