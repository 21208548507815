import * as React from "react";
const SvgCarouselArrow = ({ highlighted }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 26"
  >
    <path
      stroke={highlighted ? "#1AE299" : "#061C57"}
      strokeOpacity={highlighted ? 1 : 0.3}
      strokeWidth={4}
      d="M14.314 1.686 3 13l11.314 11.314"
    />
  </svg>
);
export default SvgCarouselArrow;
