import * as React from "react";
const SvgBrugadaSyndromeExpandedPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.094 27.881c-2.411-6.565 5.546-9.723 1.353-15.62l-1.203-1.157a.174.174 0 0 1 0-.253l3.268-3.515"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 32.595h6.184l1.86-5.091 3.207 13.984 5.17-16.835 9.289 9.913 2.33-1.75h3.421"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.797 5.638c-.595-.842-1.242-1.34-2.111-1.796a.445.445 0 0 1-.244-.444l.214-1.723a.45.45 0 0 0-.398-.493l-1.512-.179a.458.458 0 0 0-.51.386l-.15 1.22a.452.452 0 0 1-.467.39c-.432-.016-.869 0-1.306.067a.45.45 0 0 1-.497-.32l-.39-1.353a.457.457 0 0 0-.56-.307l-1.47.394a.44.44 0 0 0-.316.544l.488 1.693a.448.448 0 0 1-.184.486c-.326.224-.63.473-.908.742a.47.47 0 0 1-.583.042L17.39 3.94a.472.472 0 0 0-.638.1l-.89 1.2a.439.439 0 0 0 .102.617l1.696 1.183c.167.116.227.32.163.506a6.391 6.391 0 0 0-.09 3.847c.668 2.332 2.548 4.025 4.792 4.619M17.917 37.509c3.07 6.481 15.713 13.038 20.27 11.167 8.587-3.528 15.517-26.717-2.38-35.7"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.943 20.503c3.41.174 5.838-1.685 6.63-7.926 1.11-8.74 12.3-10.366 12.3-10.366a.535.535 0 0 1 .686.29l2.41 6a.508.508 0 0 1-.3.665c-3.935 1.514-9.837 3.867-7.597 14.673M53 4.5v5M53 4.5v5M55.5 7h-5M53 13a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
    />
  </svg>
);
export default SvgBrugadaSyndromeExpandedPanel;
