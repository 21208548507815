import React, { useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "../../components";
import { GenseqLogo } from "../../assets/svgs";
import { HeaderPattern } from "../../assets/animated-svgs";
import { scrollToAnchor } from "../../utils";
import { useWindowSize } from "../../hooks";

const Header = () => {
  const { windowWidth } = useWindowSize();
  const containerRef = useRef(null);

  const { backgroundImage } = useStaticQuery(
    graphql`
      query {
        backgroundImage: file(
          relativePath: { eq: "assets/images/header-background.png" }
        ) {
          id
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  const fadeIn = {
    hidden: { opacity: 0, left: "200px" },
    visible: {
      opacity: 1,
      left: "0",
      transition: {
        duration: 0.5,
        delay: 1,
      },
    },
  };

  return (
    <Container id="top" ref={containerRef}>
      {windowWidth >= 1024 && <GreenGradient />}
      <OrangeGradient />
      <Pattern>
        <HeaderPattern containerRef={containerRef} />
      </Pattern>
      <Background>
        <GatsbyImage
          image={getImage(backgroundImage)}
          alt="Background gradient"
          style={{ zIndex: 0, height: "100%" }}
        />
      </Background>
      <Content
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        {windowWidth >= 1024 && (
          <LogoContainer variants={fadeIn} initial="hidden" animate="visible">
            <GenseqLogo width={292} />
          </LogoContainer>
        )}
        <h1>Advanced Genomics Solutions</h1>
        <p>
          Genseq provides ISO 15189 accredited clinical genetic testing services
          to healthcare professionals and a comprehensive range of genomic
          solutions to the biopharma and clinical research sectors worldwide.
          <Button
            text="Our Services"
            id="our-services-header"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              scrollToAnchor("services", {
                duration: 1200,
                offset: -120,
              });
            }}
          />
        </p>
      </Content>
    </Container>
  );
};

const Container = styled.header`
  background: var(--genseq-blue);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Background = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`;

const GreenGradient = styled.div`
  position: absolute;
  background: linear-gradient(
    280deg,
    rgba(26, 226, 153, 1) 0%,
    rgba(26, 226, 153, 0) 50%
  );
  mix-blend-mode: hard-light;
  width: 600px;
  height: 150%;
  top: -20%;
  right: 0;
  z-index: 4;

  transform-origin: center center;
  animation: moveVertical 5s ease infinite alternate;
  opacity: 1;
  z-index: 3;

  @keyframes moveVertical {
    0% {
      transform: translate(25%, -20%);
    }
    50% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(10%, -20%);
    }
  }
`;

const Pattern = styled.div`
  position: absolute;
  top: 52%;
  right: -30%;
  height: 80%;
  width: 100%;
  z-index: 2;
  transform: translate(0, -50%);
  svg {
    height: 100%;
    width: auto;
    right: 0;
  }
  @media screen and (min-width: 1024px) {
    top: 50%;
    right: -55%;
    svg {
      width: 100%;
    }
  }
`;

const OrangeGradient = styled.div`
  position: absolute;
  background: linear-gradient(
    -280deg,
    rgba(255, 125, 80, 1) 0%,
    rgba(255, 125, 80, 0) 40%
  );
  mix-blend-mode: hard-light;
  width: 400px;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;

  transform-origin: center center;
  animation: moveHorizontal 3s ease infinite alternate;
  opacity: 1;
  z-index: 3;

  @keyframes moveHorizontal {
    0% {
      transform: translate(-180px, 0);
    }
    50% {
      transform: translate(-120px, 40px);
    }
    100% {
      transform: translate(-140px, 0);
    }
  }
`;

const Content = styled(motion.div)`
  display: flex;
  color: white;
  flex-direction: column;
  gap: 1.25rem;
  grid-column: 1 / 7;
  padding: 0 1.25rem;
  margin: 12rem 0 10rem 0;
  position: relative;
  z-index: 4;
  justify-content: flex-end;
  h1 {
    font-size: 2.875rem;
    font-weight: 400;
    max-width: 925px;
  }
  p {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    font-weight: 300;
    gap: 2rem;
    max-width: 690px;
  }
  @media screen and (min-width: 1024px) {
    justify-content: center;
    gap: 3.25rem;
    margin: 6rem 0 9rem 0;
    padding: unset;
    grid-column: 2 / 12;
    h1 {
      font-size: 5.375rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const LogoContainer = styled(motion.div)``;

export default Header;
