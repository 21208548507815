import * as React from "react";
const SvgBloodSample = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 56 38"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#blood-sample_svg__a)"
    >
      <path
        stroke="#061C57"
        d="M16.342 9.755h5.324L27.99 5.49l1.057-1.762c1.693-2.82 5.726-2.85 7.463-.057L33.7 9.758h16.25c1.649 0 2.987 1.35 2.987 3.022 0 1.668-1.335 3.022-2.987 3.022h-16.25"
      />
      <path
        stroke="#061C57"
        d="M32.908 20.065h7.111c.782 0 1.415-.64 1.415-1.43v-2.839"
      />
      <path
        stroke="#061C57"
        d="M32.908 24.951h5.697c.782 0 1.414-.64 1.414-1.431v-3.455M37.17 24.951v3.632c0 .792-.633 1.431-1.415 1.431H23.248l-4.479-2.933h-2.427M16.342 6.692H1.586v21.99h14.756V6.691Z"
      />
      <path
        stroke="#FF7D50"
        d="M54.414 31.102c0 2.76-2.211 4.998-4.941 4.998s-4.941-2.237-4.941-4.998c0-2.762 4.941-9.552 4.941-9.552s4.941 6.79 4.941 9.552Z"
      />
      <path
        stroke="#FF7D50"
        d="M49.476 33.167a2.053 2.053 0 0 1-2.041-2.065M49.476 19.282v-1.023"
      />
    </g>
    <defs>
      <clipPath id="blood-sample_svg__a">
        <path fill="#fff" d="M0 0h56v37.692H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBloodSample;
