import * as React from "react";
const SvgDcmAndConductionDefectsExpandedPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 54"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M28.296 52.968c27.896 1.217 28.144-33.01-2.556-38.918"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.288 6.057c-.639-.919-1.333-1.462-2.266-1.96a.487.487 0 0 1-.262-.483l.23-1.878a.488.488 0 0 0-.427-.538l-1.623-.195a.493.493 0 0 0-.547.421l-.16 1.33a.488.488 0 0 1-.502.425c-.464-.018-.932 0-1.401.073a.484.484 0 0 1-.533-.349l-.419-1.474a.49.49 0 0 0-.602-.335l-1.576.43a.48.48 0 0 0-.34.592l.524 1.846a.492.492 0 0 1-.198.53c-.349.244-.675.515-.974.81a.499.499 0 0 1-.625.044l-1.613-1.14a.501.501 0 0 0-.685.109l-.956 1.307a.483.483 0 0 0 .11.674l1.82 1.29c.18.126.244.348.175.551a7.072 7.072 0 0 0-.097 4.194c.717 2.542 2.735 4.388 5.143 5.035"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.713 22.26c3.658.19 6.264-1.836 7.114-8.64 1.19-9.527 13.2-11.3 13.2-11.3a.571.571 0 0 1 .735.317l2.587 6.541a.557.557 0 0 1-.322.724c-4.223 1.651-10.556 4.216-8.153 15.996M43 4.5v5M43 4.5v5M45.5 7h-5M43 13a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M23.884 30.309c0 7.45-9.78 7.45.772 22.338C12.992 50.114 3.704 37.235 1.364 29.214c-2.127-7.302 5.952-10.6 1.453-17.028l-1.292-1.262a.192.192 0 0 1 0-.276l3.507-3.831"
    />
  </svg>
);
export default SvgDcmAndConductionDefectsExpandedPanel;
