import * as React from "react";
const SvgAortopathyPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.221 48.617c10.12-7.432 13.486-25.616-.79-32.797"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M23.225 31.401c0 3.114 8.472 6.878-.589 17.599C10.314 47.042 3.472 35.412 1.333 28.343c-1.946-6.435 5.443-9.341 1.328-15.007l-1.18-1.113a.165.165 0 0 1 0-.243l3.206-3.377"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.392 6.215c-.748-1.033-1.564-1.607-2.72-2.157l.35-2.743L14.273 1l-.294 2.309a8.111 8.111 0 0 0-2.395.12l-.707-2.41-2.668.707.794 2.699c-.614.37-1.16.817-1.63 1.324L4.871 4.046 3.254 6.18l2.669 1.818a6.995 6.995 0 0 0-.236 4.63c.76 2.595 2.896 4.477 5.447 5.138"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.137 22.418c3.909.196 6.695-1.893 7.607-8.899C17.017 3.707 29.848 1.881 29.848 1.881a.62.62 0 0 1 .786.327l2.984 7.272c-4.484 1.707-11.957 4.035-9.28 16.69"
    />
  </svg>
);
export default SvgAortopathyPanel;
