import React, { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useWindowSize } from "../../../hooks";
import { BannerGridRight } from "../../../assets/svgs";

const LaboratoryAndTechnology = ({ slides }) => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: {
            relativeDirectory: { eq: "assets/images/our-team/laboratory" }
          }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );
  const { windowWidth } = useWindowSize();
  const containerRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"],
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], {
      ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
    });
  };

  const down = useParallax(scrollYProgress, 25);
  const up = useParallax(scrollYProgress, -15);
  return (
    <Container>
      <Images ref={containerRef}>
        <Left style={{ y: up }}>
          <GatsbyImage
            image={getImage(images.edges[0].node.childImageSharp)}
            alt={images.edges[0].node.name}
          />
        </Left>
        <SVGContainerLeft>
          <BannerGridRight />
        </SVGContainerLeft>
        {windowWidth >= 780 && (
          <>
            <SVGContainerRight>
              <BannerGridRight />
            </SVGContainerRight>
            <Center style={{ y: up }}>
              <GatsbyImage
                image={getImage(images.edges[1].node.childImageSharp)}
                alt={images.edges[1].node.name}
              />
            </Center>
          </>
        )}
        <Right style={{ y: down }}>
          <GatsbyImage
            image={getImage(images.edges[2].node.childImageSharp)}
            alt={images.edges[2].node.name}
          />
          <GatsbyImage
            image={getImage(images.edges[3].node.childImageSharp)}
            alt={images.edges[3].node.name}
          />
        </Right>
      </Images>
    </Container>
  );
};

const Container = styled.section`
  grid-column: 1 / 7;
  padding: 0 1.25rem 4rem 1.25rem;
  gap: 3rem;
  h3,
  p {
    text-align: center;
  }
  @media screen and (min-width: 780px) {
    grid-column: 1 / 7;
    padding: 4rem 0 6rem 0;
    gap: 6rem;
    h3,
    p {
      text-align: left;
    }
  }
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 13;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 1 / 13;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
`;

const Left = styled(motion.div)`
  grid-column: 1 / 9;
  z-index: 1;
  div {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    border-radius: 0.25rem;
  }
  @media screen and (min-width: 780px) {
    grid-column: 1 / 6;
  }
`;

const Center = styled(motion.div)`
  grid-column: 6 / 9;
  margin-top: 3rem;
  margin-left: 0.75rem;
  margin-right: 3rem;
  div {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    border-radius: 0.25rem;
  }
`;

const Right = styled(motion.div)`
  grid-column: 6 / 13;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  position: relative;
  z-index: 3;
  margin-top: -3rem;
  div {
    border-radius: 0.25rem;
    &:nth-of-type(1) {
      grid-column: 1 / 5;
      z-index: 3;
    }
    &:nth-of-type(2) {
      box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
      grid-column: 5 / 7;
      margin-left: 0.75rem;
      z-index: 3;
    }
  }
  @media screen and (min-width: 780px) {
    margin-top: 6rem;
    grid-column: 9 / 13;
  }
`;

const SVGContainerLeft = styled.div`
  left: -1.25rem;
  bottom: 0rem;
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  svg {
    width: 60%;
    height: auto;
    margin-right: auto;
    transform: rotate(180deg);
  }
  @media screen and (min-width: 780px) {
    left: 0;
    svg {
      width: 30%;
    }
  }
`;

const SVGContainerRight = styled.div`
  right: -1.25rem;
  top: 0.5rem;
  position: absolute;
  display: flex;
  z-index: 2;
  width: 100%;
  svg {
    width: 50%;
    height: auto;
    margin-left: auto;
  }
  @media screen and (min-width: 780px) {
    right: 0;
    svg {
      width: 30%;
    }
  }
  @media screen and (min-width: 1024px) {
    top: -4rem;
  }
`;

export default LaboratoryAndTechnology;
