import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import { navigate, Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import DropdownNavigation from "./DropdownNavigation";
import ServicesMenu from "./ServicesMenu";
import { Button, Dialog, ContactUsForm } from "../../components";
import { LocalContext } from "../../context";
import {
  GenseqLogo,
  MenuIcon,
  ExternalLink,
  ChevronDown,
} from "../../assets/svgs";
import { useWindowSize } from "../../hooks";

const NavigationBar = ({ scrollYProgress, pathname }) => {
  const { windowWidth } = useWindowSize();
  const [scrollY, setScrollY] = useState(0);
  const [showResourcesMenu, setShowResourcesMenu] = useState(false);
  const [showOrderTestMenu, setShowOrderTestMenu] = useState(false);
  const contactFormDialogRef = useRef(null);
  const {
    showContactFormDialog,
    setShowContactFormDialog,
    dropdownOpen,
    setDropdownOpen,
    showServicesMenu,
    setShowServicesMenu,
    setShowRequestAccountFormDialog,
    setShowRequestFormDialog,
  } = useContext(LocalContext);

  const closeContactFormDialog = () => {
    contactFormDialogRef.current.setAttribute("data-state", "close");
    contactFormDialogRef.current.children[0].setAttribute(
      "data-state",
      "close"
    );
    setShowContactFormDialog(false);
  };

  useEffect(() => {
    scrollYProgress.onChange((number) => setScrollY(number));
  }, [scrollYProgress, scrollY]);

  useEffect(() => {
    if (showResourcesMenu) {
      setShowServicesMenu(false);
    } else if (showServicesMenu) {
      setShowResourcesMenu(false);
    }
  }, [showServicesMenu, showResourcesMenu]);

  console.log(showServicesMenu, 'hello');

  const fadeOut = {
    hidden: { opacity: 0, scale: 1, left: 0, translateX: "0%" },
    visible: {
      opacity: 1,
      scale: 1,
      left: 0,
      translateX: "0%",
      transition: {
        duration: 0.5,
        scale: {
          duration: 0.1,
        },
      },
    },
    scale: {
      opacity: 1,
      scale: 0.72,
      left: "50%",
      translateX: "-50%",
      transition: {
        duration: 0.3,
        scale: {
          duration: 0.3,
          delay: 0.1,
        },
      },
    },
  };

  const colorChange = {
    dark: {
      background: "rgba(6, 28, 87, 0.9)",
    },
    darker: {
      background: "rgba(6, 28, 87, 1)",
    },
    light: {
      background: "rgba(6, 28, 87, 0)",
      transition: {
        duration: 0.5,
      },
    },
  };

  const slideIn = {
    hidden: { x: "100vw", transition: { duration: 0.75 } },
    visible: { x: "0", transition: { duration: 0.75 } },
  };

  return (
    <AnimatePresence initial={false} mode="wait">
      <Container
        key={pathname}
        dropdownOpen={dropdownOpen}
        variants={colorChange}
        onMouseLeave={() => {
          setShowServicesMenu(false);
          setShowResourcesMenu(false);
          setShowOrderTestMenu(false);
        }}
        initial="light"
        animate={
          pathname.includes("cookie-policy")
            ? "dark"
            : windowWidth >= 1300 && Math.round(scrollY * 100) / 100 > 0.1
            ? "dark"
            : dropdownOpen
            ? "darker"
            : "light"
        }
      >
        {windowWidth < 1300 ? (
          <>
            <LogoContainer
              variants={fadeOut}
              initial="hidden"
              type="button"
              aria-label="Navigate to Homepage"
              onClick={() => navigate("/")}
              animate={
                dropdownOpen
                  ? "scale"
                  : Math.round(scrollY * 100) / 100 < 0.1
                  ? "visible"
                  : "hidden"
              }
            >
              <GenseqLogo />
            </LogoContainer>
            <MenuToggle
              dropdownOpen={dropdownOpen}
              dark={Math.round(scrollY * 100) / 100 > 0.1}
              onClick={() => setDropdownOpen((previousState) => !previousState)}
            >
              <MenuIcon scrollY={scrollY} dropdownOpen={dropdownOpen} />
            </MenuToggle>
            <DropdownNavigation dropdownOpen={dropdownOpen} showOrderTestMenu={showOrderTestMenu} setShowOrderTestMenu={setShowOrderTestMenu} />
          </>
        ) : (
          <>
            <LogoContainer
              variants={fadeOut}
              initial="hidden"
              type="button"
              aria-label="Navigate to Homepage"
              onClick={() => navigate("/")}
              animate={
                dropdownOpen
                  ? "scale"
                  : pathname !== "/"
                  ? "visible"
                  : pathname === "/" && Math.round(scrollY * 100) / 100 > 0.1
                  ? "visible"
                  : "hidden"
              }
            >
              <GenseqLogo />
            </LogoContainer>
            <Links>
              <Anchor
                type="button"
                id="about-us-navigation"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/about-us");
                }}
                onMouseEnter={() => {
                  setShowServicesMenu(false);
                  setShowResourcesMenu(false);
                }}
                color={
                  pathname.includes("about-us")
                    ? "var(--genseq-green)"
                    : "#FFFFFF"
                }
              >
                About Us
              </Anchor>
              <Anchor
                type="button"
                id="services-navigation"
                onMouseEnter={() => {
                  setShowResourcesMenu(false);
                  setShowServicesMenu(true);
                }}
                color={showServicesMenu ? "var(--genseq-green)" : "#FFFFFF"}
              >
                Services
              </Anchor>
              <Anchor
                type="button"
                id="resources-navigation"
                onMouseEnter={() => {
                  setShowServicesMenu(false);
                  setShowResourcesMenu(true);
                }}
                onClick={() => setShowResourcesMenu(false)}
                color={
                  pathname.includes("resources") || showResourcesMenu
                    ? "var(--genseq-green)"
                    : "#FFFFFF"
                }
              >
                Resources
                {showResourcesMenu && (
                  <SmallMenu
                    variants={slideIn}
                    initial="hidden"
                    exit="hidden"
                    animate={showResourcesMenu ? "visible" : "hidden"}
                  >
                    <Link to="/services/clinical/resources">
                      <h5>Clinical</h5>
                    </Link>
                    <Link to="/services/clinical/resources/downloadable-forms">
                      Documents
                    </Link>
                    <Link to="/services/clinical/resources/sample-requirements">
                      Sample Requirements
                    </Link>
                    <br />
                    <Link to="/services/biopharma-and-research/resources">
                      <h5>Biopharma/ Research Resources</h5>
                    </Link>
                    <Link to="/services/biopharma-and-research/resources/downloadable-forms">
                      Documents
                    </Link>
                    <Link to="/services/biopharma-and-research/resources/sample-requirements">
                      Sample Requirements
                    </Link>
                  </SmallMenu>
                )}
              </Anchor>
              <ActionButtons>
                <Button
                  type="button"
                  solid="var(--genseq-purple)"
                  text="Contact Us"
                  id="contact-us-navigation"
                  onClick={() => setShowContactFormDialog(true)}
                  onMouseEnter={() => setShowServicesMenu(false)}
                />
                <div style={{ position: "relative" }}>
                  <Button
                    type="button"
                    solid={showOrderTestMenu ? "var(--genseq-green)" : null}
                    text="Order A Test"
                    id="order-test-navigation"
                    onClick={() => setShowOrderTestMenu(true)}
                    open={showOrderTestMenu}
                    maxWidth="200px"
                    onMouseEnter={() => {
                      setShowServicesMenu(false);
                      setShowOrderTestMenu(true);
                    }}
                  >
                    <ChevronDown />
                  </Button>
                  {showOrderTestMenu && (
                    <SmallMenu
                      variants={slideIn}
                      initial="hidden"
                      exit="hidden"
                      animate={showOrderTestMenu ? "visible" : "hidden"}
                      style={{ top: "4.125rem" }}
                    >
                      <h5>Clinical</h5>
                      <button
                        type="button"
                        onClick={() => setShowRequestAccountFormDialog(true)}
                      >
                        Account Registration
                      </button>
                      <Link to="/clinical-portal">Login</Link>
                      <h5>Biopharma/ Research</h5>
                      <button
                        type="button"
                        onClick={() => setShowRequestFormDialog(true)}
                      >
                        Request A Quote
                      </button>
                    </SmallMenu>
                  )}
                </div>
              </ActionButtons>
            </Links>
          </>
        )}
        <InnerContainer>
          <AnimatePresence>
            <MenuContainer
              variants={slideIn}
              initial="hidden"
              exit="hidden"
              animate={showServicesMenu ? "visible" : "hidden"}
            >
              <ServicesMenu setDropdownOpen={setDropdownOpen} setShowServicesMenu={setShowServicesMenu} />
            </MenuContainer>
          </AnimatePresence>
        </InnerContainer>
        <Dialog
          large
          ref={contactFormDialogRef}
          onDismiss={closeContactFormDialog}
          isVisible={showContactFormDialog}
        >
          <ContactUsForm closeContactFormDialog={closeContactFormDialog} />
        </Dialog>
      </Container>
    </AnimatePresence>
  );
};

const Container = styled(motion.nav)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: ${({ dropdownOpen }) => (dropdownOpen ? "4rem" : "6rem")};
  position: fixed;
  gap: 3rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: all 0.3s ease-out;
  max-width: 100vw;
  @media screen and (min-width: 1024px) {
    height: 5rem;
    background: transparent;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const MenuToggle = styled(motion.button)`
  align-items: center;
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: ${({ dropdownOpen }) =>
    dropdownOpen ? "1.5rem 1.25rem" : "2rem 1.25rem"};
  transition: all 0.3s ease-out;
  &:before {
    border-radius: 3rem;
    background: ${({ dark }) =>
      dark ? "rgba(6, 28, 87, 0.9)" : "rgba(6, 28, 87, 0)"};
    content: "";
    height: 3rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    z-index: 0;
  }
  svg {
    width: 1.125rem;
    height: auto;
    z-index: 8;
  }
  @media screen and (min-width: 1024px) {
    right: 3rem;
  }
`;

const Links = styled(motion.div)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  grid-column: 5/12;
  gap: 3rem;
  position: relative;
  @media screen and (min-width: 1024px) {
    grid-column: 3/12;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 4/12;
  }
`;

const Anchor = styled(motion.button)`
  color: ${({ color }) => color};
  cursor: pointer;
  position: relative;
  &:hover {
    color: var(--genseq-green);
  }
`;

const ExternalAnchor = styled(motion.a)`
  align-items: center;
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  gap: 0.5rem;
  justify-content: center;
  text-decoration: none;
  svg {
    height: 12px;
    width: 12px;
  }
  &:hover {
    color: var(--genseq-green);
    svg {
      path {
        stroke: var(--genseq-green);
      }
    }
  }
`;

const LogoContainer = styled(motion.button)`
  align-items: start;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  grid-column: 1/7;
  padding-left: 1.25rem;
  position: relative;
  height: 100%;
  width: max-content;
  svg {
    width: 180px;
    height: auto;
  }
  @media screen and (min-width: 1024px) {
    grid-column: 2/3;
    padding-left: 0;
  }
  @media screen and (min-width: 1300px) {
    grid-column: 2/3;
  }
`;

const MenuContainer = styled(motion.div)`
  background-color: white;
  border-radius: 0.625rem;
  grid-column: 1/7;
  padding: 0 2.625rem;
  position: relative;
  width: 100%;
  @media screen and (min-width: 1024px) {
    grid-column: 3/12;
    padding: 0.5rem 3.625rem;
    margin-left: -4rem;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const SmallMenu = styled(motion.div)`
  left: 0;
  background: white;
  border-radius: 10px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  position: absolute;
  top: 3rem;
  width: 240px;
  padding: 1.5rem 1.25rem;
  h5 {
    color: var(--genseq-blue);
    text-align: left;
    font-size: 1rem;
  }
  a {
    color: var(--genseq-blue);
    font-weight: 300;
    font-size: 1rem;
    margin-left: 1.5rem;
    text-align: left;
    text-decoration: none;
    h5 {
      margin-left: -1.5rem;
    }
  }
  button {
    cursor: pointer;
    color: var(--genseq-blue);
    font-weight: 300;
    font-size: 1rem;
    margin-left: 1.5rem;
    text-align: left;
    text-decoration: none;
    h5 {
      margin-left: -1.5rem;
    }
  }
`;

const InnerContainer = styled.div`
  left: 0;
  position: absolute;
  top: 5rem;
  width: 100%;
  height: 0;
  display: none;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
`;
const ActionButtons = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export default NavigationBar;
