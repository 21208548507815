import React from 'react';
import { hexToRGB } from '../utils';

const customDropdownMenuStyles = ({ windowWidth }) => ({
    container: (provided, state) => ({
      ...provided,
      alignItems: 'center',
      alignSelf: 'stretch',
      background: '#FFFFFF',
      border: state.isFocused ? '1px solid var(--genseq-orange)' : '1px solid #e5e5e5',
      borderRadius: '2px',
      display: 'flex',
      fontFamily: "'Outfit', sans-serif",
      fontSize: '1rem',
      height: '52px',
      padding: '1rem 0.5rem',
      marginLeft: windowWidth >= 780 ? '-0.5rem' : '0'
    }),
    control: (provided, state) => ({
      ...provided,
      background: 'transparent',
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'none',
      padding: '0',
      transition: 'none',
      cursor: 'pointer',
      height: '100%',
      width: '100%'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      alignItems: 'start'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      '&:hover': {
        color: 'var(--genseq-blue)'
      }
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'var(--genseq-orange)',
      '&:hover': {
        color: 'var(--genseq-orange)'
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    input: (provided, state) => ({
      ...provided,
      color: 'var(--genseq-blue)',
      margin: 0,
    }),
    menu: (provided) => ({
      ...provided,
      background: '#FFFFFF',
      border: 'none',
      borderRadius: '2px',
      color: 'var(--genseq-blue)',
      fontFamily: "'Outfit', sans-serif",
      fontSize: '1rem',
      fontWeight: '400',
      left: '0',
      margin: '0',
      top: 'calc(100% + 2px)',
      width: '100%'
    }),
    menuList: (provided) => ({
      ...provided,
      height: '100%',
      maxHeight: '200px',
      overflowY: 'auto',
      padding: '0',
      '::-webkit-scrollbar': {
        width: '0.5rem',
        background: 'rgba(217, 217, 217, 0.2)',
        borderRadius: '0.625rem'
      },
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(217, 217, 217, 0.2)',
        borderRadius: '0.625rem'
      }
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'var(--genseq-blue)'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? 'rgba(255, 125, 80, 0.20)' : 'transparent',
      padding: '0.5rem',
      color: 'var(--genseq-blue)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255, 125, 80, 0.20)'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#e5e5e5',
      fontFamily: "'Outfit', sans-serif",
      fontSize: '1rem',
      fontWeight: '400',
      margin: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--genseq-blue)',
      fontWeight: '400',
      margin: 0
    }),
    multiValueContainer: (provided) => ({
      ...provided,
      position: 'absolute'
    }),
    multiValue: (provided) => ({
      ...provided,
      fontWeight: '400',
      backgroundColor: `${hexToRGB({ color: '#061C57', alpha: 0.25 })}`,
      margin: 0
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'var(--genseq-blue)'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: 'var(--genseq-blue)',
      '&:hover': {
        backgroundColor: `${hexToRGB({ color: '#061C57', alpha: 0.25 })}`,
        color: 'var(--genseq-blue)'
      }
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0',
      gap: '0.25rem'
    })
  });


  export default customDropdownMenuStyles;