import React from "react";
import styled from "styled-components";
import { useWindowSize } from "../../hooks";

const PolicyTable = () => {
  const { windowWidth } = useWindowSize();
  return (
    <Table>
      <h5>Purpose/Activity</h5>
      <h5>Type of data</h5>
      <h5>Legal basis for processing</h5>
      <p>To provide the Services to you.</p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
          <li>Location Data</li>
        </ul>
      </p>
      <p>Performance of a contract.</p>
      <p>
        To respond to your queries and to provide you with the information you
        request from us in relation to our products or Services.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        <ul>
          <li>
            Necessary for our legitimate interests (to respond to new or
            existing customer queries and grow our business)
          </li>
          <li>Performance of a contact with you</li>
        </ul>
      </p>
      <p>
        To manage our relationship with you, including notifying you about
        changes to the Services, or our Privacy Policy.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        <ul>
          <li>Performance of a contract</li>
          <li>Necessary to comply with a legal obligation</li>
          <li>
            Necessary for our legitimate interests (to keep our records updated
            and to study how customers use our products and services).
          </li>
        </ul>
      </p>
      <p>
        To provide you with information about services we offer that are similar
        to those that you have enquired about.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        Necessary for our legitimate interests (to develop our products or
        Services and grow our business)
      </p>
      <p>
        Where you have given us your consent to do so, to provide you with
        information about other services we feel may interest you.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>Consent</p>
      <p>
        To ensure that content is presented in the most effective manner for you
        and for your computer or device.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        Necessary for our legitimate interests (to keep our Site and the
        Services updated and relevant and to develop and grow our business).
      </p>
      <p>
        To administer and protect our business, our Site and for internal
        operations, including troubleshooting, data analysis, testing, research,
        statistical and survey purposes.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        Necessary for our legitimate interests (for running our business and as
        part of our efforts to keep our Site and the Services safe and secure)
      </p>
      <p>
        To use data analytics to improve or optimise our Site, marketing,
        customer relationships and experiences.
      </p>
      <p>
        <ul>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        Necessary for our legitimate interests (to define types of customers for
        our products and services, to keep our Site and the Services updated and
        relevant, to develop and grow our business and inform our marketing
        strategy).
      </p>
      <p>
        To measure or understand the effectiveness of advertising we serve to
        you and others, and, where applicable, to deliver relevant advertising
        to you.
      </p>
      <p>
        <ul>
          <li>Identity Data</li>
          <li>Technical Data</li>
          <li>Usage Data</li>
        </ul>
      </p>
      <p>
        Necessary for our legitimate interests (to study how customers use our
        products or Services, to develop them, to grow our business and to
        inform our marketing strategy). 
      </p>
    </Table>
  );
};

const Table = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  position: relative;
  h5 {
    font-size: 0.625rem !important;
    padding: 0.75rem 0.5rem;
    &:not(:last-of-type) {
      border-right: 1px solid #e5e5e5;
    }
    border-bottom: 1px solid #e5e5e5;
  }
  p {
    font-size: 0.625rem !important;
    padding: 0.75rem 0.5rem;
    &:not(:nth-last-child(-n + 3)) {
      border-bottom: 1px solid #e5e5e5;
    }
    &:not(:nth-child(3n)) {
      border-right: 1px solid #e5e5e5;
    }
  }
  ul {
    margin-left: 1rem;
  }
  @media screen and (min-width: 780px) {
    h5,
    p {
      font-size: 1rem;
      padding: 1rem 1.25rem;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
    ul {
      margin-left: 0.5rem;
    }
  }
`;

export default PolicyTable;
