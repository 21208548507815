import * as React from "react";
const SvgComprehensiveCardiomyopathyPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 44 54"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.174 5.635c-.593-.842-1.236-1.34-2.102-1.796a.445.445 0 0 1-.243-.443l.213-1.721a.449.449 0 0 0-.396-.494l-1.505-.178a.456.456 0 0 0-.507.386l-.15 1.219a.45.45 0 0 1-.464.39c-.43-.017-.866 0-1.3.066a.449.449 0 0 1-.495-.32l-.388-1.351a.455.455 0 0 0-.558-.307l-1.462.394a.44.44 0 0 0-.316.543l.486 1.692a.448.448 0 0 1-.183.485 7.024 7.024 0 0 0-.904.742.467.467 0 0 1-.58.042L9.824 3.939a.469.469 0 0 0-.635.1l-.887 1.198a.44.44 0 0 0 .102.617l1.689 1.182c.166.116.226.32.162.506a6.409 6.409 0 0 0-.09 3.844c.665 2.33 2.537 4.022 4.77 4.615"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.948 6.327 5.695 9.839a.174.174 0 0 0 0 .253l1.198 1.157c4.173 5.892-3.317 8.914-1.347 15.607 2.31 7.84 6.816 16.556 24.977 21.776 8.905 2.559 15.445-26.694-2.37-35.67"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.365 20.487c3.394.174 5.81-1.683 6.6-7.92C20.068 3.835 31.207 2.21 31.207 2.21a.532.532 0 0 1 .682.29l2.4 5.996a.508.508 0 0 1-.299.663c-3.917 1.513-9.792 3.864-7.562 14.662"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.327 12.464c.99.7 1.88 1.492 2.686 2.363M3.05 31.59a128.79 128.79 0 0 1-.682-1.82C1.934 28.58 1.171 26.467 1 24.14M8.65 41.128c-1.89-2.032-3.207-4.172-4.28-6.435M17.835 47.7c-2.746-1.377-4.95-2.803-6.753-4.284M28.36 51.85c-2.907-.92-5.452-1.866-7.694-2.832M38.305 49.752c-1.905 2.509-4.259 3.777-6.82 3.04M42.384 39.477c-.503 2.733-1.304 5.262-2.327 7.377M42.723 28.274c.336 2.654.358 5.365.11 7.973M39.589 18.376c1.185 2.086 2.033 4.404 2.587 6.821M1.405 20.226a7.61 7.61 0 0 1 1.82-3.015"
    />
  </svg>
);
export default SvgComprehensiveCardiomyopathyPanel;
