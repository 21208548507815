import * as React from "react";
const SvgHcmCorePanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 50"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.153 48.458c10.554-8.106 13.815-28.354-2.355-35.33"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.786 38.624c6.806-1.632 6.806-14.383-1.16-17.822"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M17.852 5.7c-.588-.854-1.228-1.359-2.088-1.821a.453.453 0 0 1-.241-.45l.212-1.745a.453.453 0 0 0-.394-.5l-1.495-.18a.455.455 0 0 0-.504.39l-.149 1.236a.45.45 0 0 1-.461.396c-.428-.017-.86 0-1.292.067a.446.446 0 0 1-.491-.324l-.386-1.37a.45.45 0 0 0-.554-.312l-1.453.4a.446.446 0 0 0-.313.55l.482 1.716a.46.46 0 0 1-.182.492 7.01 7.01 0 0 0-.897.752.457.457 0 0 1-.576.042L5.583 3.98a.46.46 0 0 0-.631.102l-.88 1.215a.452.452 0 0 0 .1.626L5.85 7.121a.447.447 0 0 1 .16.512 6.626 6.626 0 0 0-.088 3.898c.66 2.363 2.52 4.078 4.739 4.68"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.107 20.76c3.372.176 5.773-1.708 6.556-8.03C15.76 3.874 26.827 2.226 26.827 2.226a.525.525 0 0 1 .677.294L29.89 8.6a.519.519 0 0 1-.297.672c-3.892 1.535-9.728 3.919-7.513 14.867"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.088 28.239c0 6.924-9.012 6.924.711 20.761C12.051 46.646 3.492 34.676 1.336 27.222-.625 20.435 6.82 17.37 2.674 11.395l-1.19-1.173a.18.18 0 0 1 0-.256l3.231-3.561"
    />
  </svg>
);
export default SvgHcmCorePanel;
