import * as React from "react";
const SvgCptvPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 50"
    {...props}
  >
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.064 29.557H27.88l-2.055 7.905-3.04-14.088-2.527 7.009-5.803-.083-2.63 8.336-2.783-12.133-1.858 5.092H1"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M43.074 34.831h-6.188l-3.13 10.719-2.783-12.345-2.788 8.1-4.368-.087-2.63 7.046-2.783-13.96-1.854 6.914h-2.711"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M29.795 5.638c-.595-.842-1.242-1.34-2.111-1.796a.445.445 0 0 1-.245-.444l.215-1.723a.45.45 0 0 0-.399-.493l-1.511-.179a.458.458 0 0 0-.51.386l-.15 1.22a.452.452 0 0 1-.466.39c-.433-.016-.87 0-1.307.067a.45.45 0 0 1-.496-.32l-.39-1.353a.457.457 0 0 0-.561-.307l-1.469.394a.44.44 0 0 0-.317.544l.488 1.693a.448.448 0 0 1-.184.486c-.325.224-.63.473-.908.742a.47.47 0 0 1-.582.042L17.389 3.94a.472.472 0 0 0-.638.1l-.89 1.199a.439.439 0 0 0 .102.618l1.696 1.183c.167.116.227.32.162.506a6.391 6.391 0 0 0-.09 3.847c.668 2.332 2.548 4.025 4.792 4.618"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13.094 26.88c-2.411-6.564 5.546-9.722 1.353-15.619l-1.203-1.158a.174.174 0 0 1 0-.253l3.267-3.515M25.443 45.023c4.66 2.963 10.081 4.747 12.745 3.652 8.586-3.528 15.514-26.716-2.381-35.7"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.941 20.502c3.41.175 5.837-1.684 6.63-7.925C27.68 3.836 38.869 2.21 38.869 2.21a.535.535 0 0 1 .685.29l2.411 6a.508.508 0 0 1-.3.665c-3.935 1.514-9.836 3.867-7.596 14.673"
    />
  </svg>
);
export default SvgCptvPanel;
