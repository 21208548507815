import * as React from "react";
const SvgFastTurnaround = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 50 48"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.577 42.334v-7.81h7.96m-3.98-7.852 2.922 2.853 6.379-6.799M7.577 28.722v-7.81h7.96m-3.98-7.851 2.922 2.852 6.379-6.812M7.577 15.096v-7.81h7.96M37.23 40.202v.568m0 1.91v3.158c0 .637-.537 1.163-1.186 1.163H2.186C1.536 47 1 46.474 1 45.837V2.163C1 1.526 1.536 1 2.186 1H36.03c.65 0 1.185.526 1.185 1.163v5.802"
    />
    <path
      stroke="#645FE6"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M39.925 10.569h-5.42m7.283 12.559-4.572 4.486-6.21-6.093M49 25.704c0 6.384-5.278 11.563-11.785 11.563-6.506 0-11.784-5.18-11.784-11.563 0-6.383 5.278-11.548 11.784-11.548C43.723 14.155 49 19.334 49 25.703Zm0-.014c0 6.384-5.278 11.563-11.785 11.563-6.506 0-11.784-5.179-11.784-11.563 0-6.383 5.278-11.548 11.784-11.548C43.723 14.141 49 19.32 49 25.689Z"
    />
  </svg>
);
export default SvgFastTurnaround;
