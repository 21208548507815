import * as React from "react";
const SvgHighProbability = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 60 60"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#high-probability_svg__a)"
    >
      <path stroke="#061C57" d="M.714.714V58.65h58.572" />
      <path
        stroke="#061C57"
        d="M27.412 29.14h-6.847v23.648h6.847V29.14ZM14.756 37.382H7.91v15.41h6.847v-15.41ZM39.921 21.972h-6.847v30.816h6.847V21.972ZM52.427 16.296H45.58v36.492h6.847V16.296Z"
      />
      <path
        stroke="#FF7D50"
        d="m48.316 5.265 5.672.725-3.465 4.548M7.283 26.887l46.701-20.9"
      />
    </g>
    <defs>
      <clipPath id="high-probability_svg__a">
        <path fill="#fff" d="M0 0h60v59.364H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHighProbability;
