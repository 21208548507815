import * as React from "react";
const SvgPoweredByIllumina = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 133 63"
    {...props}
  >
    <g clipPath="url(#powered-by-illumina_svg__a)">
      <mask
        id="powered-by-illumina_svg__b"
        width={133}
        height={63}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path fill="#fff" d="M132.708.235H.02v62.472h132.688V.235Z" />
      </mask>
      <g mask="url(#powered-by-illumina_svg__b)">
        <mask
          id="powered-by-illumina_svg__c"
          width={133}
          height={63}
          x={0}
          y={0}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: "luminance",
          }}
        >
          <path fill="#fff" d="M132.708.235H.02v62.472h132.688V.235Z" />
        </mask>
        <g mask="url(#powered-by-illumina_svg__c)">
          <mask
            id="powered-by-illumina_svg__d"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__d)">
            <path
              fill="#FEFEFE"
              d="M132.491 5.013c0-2.64-1.951-4.778-4.372-4.778H4.569C2.184.235.233 2.373.233 5.013v17.832h132.257V5.013Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__e"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__e)">
            <path
              stroke="#696969"
              strokeMiterlimit={10}
              strokeWidth={0.74}
              d="M132.491 5.013c0-2.64-1.951-4.778-4.372-4.778H4.569C2.184.235.233 2.373.233 5.013v17.832h132.257V5.013Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__f"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__f)">
            <path
              fill="#696969"
              d="M14.352 5.624h4.442c1.264 0 2.49.617 2.49 2.392 0 1.552-1.048 2.385-2.884 2.385h-3.216v3.474h-.832V5.624Zm.832 3.982h3.324c1.41 0 1.843-.649 1.843-1.59 0-1.266-.687-1.629-1.66-1.629h-3.5v3.22h-.007Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__g"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__g)">
            <path
              fill="#696969"
              d="M28.719 5.407c2.345 0 4.474 1.413 4.474 4.346 0 2.932-2.13 4.338-4.474 4.338-2.345 0-4.512-1.406-4.512-4.338 0-2.933 2.167-4.346 4.512-4.346Zm0 7.889c1.805 0 3.54-1.043 3.54-3.543s-1.735-3.55-3.54-3.55-3.572 1.05-3.572 3.55 1.735 3.543 3.572 3.543Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__h"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__h)">
            <path
              fill="#696969"
              d="M47.231 5.624h.94l-2.776 8.25h-.979L41.931 6.82h-.038l-2.453 7.055h-.978l-2.74-8.251h.935l2.313 7.131 2.453-7.131h1.048l2.453 7.131 2.307-7.131Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__i"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__i)">
            <path
              fill="#696969"
              d="M51.168 5.624h6.533v.763h-5.707v2.818h5.522v.796h-5.522v3.079h5.815v.795h-6.641V5.624Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__j"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__j)">
            <path
              fill="#696969"
              d="M61.272 5.624h4.512c1.41 0 2.669.47 2.669 2.207 0 .904-.54 1.775-1.62 2.023 1.188.217 1.404.948 1.474 2.316.038.509.07 1.52.286 1.699h-.94c-.147-.179-.179-.796-.217-1.699 0-1.444-.578-1.921-1.989-1.921H62.13v3.613h-.864V5.617l.006.007Zm.864 3.836h3.61c.94 0 1.773-.363 1.773-1.629 0-1.19-.903-1.444-1.697-1.444h-3.68V9.46h-.006Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__k"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__k)">
            <path
              fill="#696969"
              d="M72.063 5.624h6.577v.763h-5.707v2.818h5.49v.796h-5.49v3.079h5.777v.795h-6.647V5.624Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__l"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__l)">
            <path
              fill="#696969"
              d="M82.066 5.624h4.01c2.383 0 3.648 1.52 3.648 4.129 0 2.315-1.233 4.122-3.54 4.122h-4.111V5.63l-.007-.006Zm.833 7.45h3.14c1.48 0 2.745-.796 2.745-3.328 0-2.207-.833-3.365-2.746-3.365h-3.14v6.692Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__m"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__m)">
            <path
              fill="#696969"
              d="M98.672 5.624h4.69c1.627 0 2.307.903 2.307 2.061 0 .795-.433 1.559-1.405 1.775v.032c1.08.146 1.735.941 1.735 2.03 0 1.736-1.443 2.347-2.707 2.347h-4.62V5.624Zm.864 3.581h3.394c1.156 0 1.874-.362 1.874-1.482 0-1.011-.648-1.342-1.588-1.342h-3.68v2.824Zm0 3.868h3.61c1.08 0 1.912-.432 1.912-1.552 0-1.196-.724-1.52-1.734-1.52H99.53v3.072h.006Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__n"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__n)">
            <path
              fill="#696969"
              d="m112.247 10.401-3.724-4.777h1.119l3.037 3.912 3.032-3.912h1.08l-3.679 4.777v3.474h-.865V10.4Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__o"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__o)">
            <path
              fill="#F79A0F"
              d="M132.491 19.626v38.056c0 2.64-1.951 4.777-4.372 4.777H4.569c-2.384 0-4.335-2.131-4.335-4.778V19.627"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__p"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__p)">
            <path
              stroke="#F79A0F"
              strokeMiterlimit={10}
              strokeWidth={0.74}
              d="M132.491 19.626v38.056c0 2.64-1.951 4.777-4.372 4.777H4.569c-2.384 0-4.335-2.131-4.335-4.778V19.627"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__q"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__q)">
            <path
              fill="#FEFEFE"
              d="M15.29 37.496h2.846l.038 15.084H15.29V37.496Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__r"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__r)">
            <path
              fill="#FEFEFE"
              d="M22.902 28.488h2.892V52.58H22.94l-.038-24.092Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__s"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__s)">
            <path
              fill="#FEFEFE"
              d="M30.7 28.488h2.884V52.58h-2.847L30.7 28.488Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__t"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__t)">
            <path
              fill="#FEFEFE"
              d="M51.023 52.58H48.17v-2.316h-.07c-.724 1.59-2.637 2.71-4.944 2.71-2.961 0-5.484-1.775-5.484-5.79V37.49h2.853v8.9c0 2.856 1.627 3.906 3.432 3.906 2.383 0 4.22-1.52 4.22-5.026l-.039-7.78h2.892v15.083l-.007.007Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__u"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__u)">
            <path
              fill="#FEFEFE"
              d="M54.702 37.496h2.669v2.354h.07c.286-.904 2.128-2.748 4.836-2.748 2.205 0 3.755.98 4.696 2.824.972-1.845 2.961-2.824 4.766-2.824 4.652 0 5.738 3.327 5.738 6.692v8.792h-2.853v-8.391c0-2.316-.508-4.415-3.247-4.415-2.74 0-3.864 1.883-3.864 4.561v8.245H64.66v-7.889c0-2.856-.432-4.917-3.178-4.917-2.09 0-3.933 1.559-3.933 4.994l.031 7.812h-2.885V37.502l.007-.006Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__v"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__v)">
            <path fill="#FEFEFE" d="M81.379 37.496h2.891V52.58H81.38V37.496Z" />
          </g>
          <mask
            id="powered-by-illumina_svg__w"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__w)">
            <path
              fill="#FEFEFE"
              d="M88.49 37.496h2.854v2.316h.07c.724-1.59 2.637-2.71 4.944-2.71 2.923 0 5.484 1.775 5.484 5.789v9.695h-2.853v-8.9c0-2.856-1.62-3.906-3.464-3.906-2.383 0-4.187 1.52-4.187 5.026v7.78h-2.854V37.502l.007-.006Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__x"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__x)">
            <path
              fill="#FEFEFE"
              d="M115.201 45.346v.942c0 2.023-1.118 4.377-4.296 4.377-1.951 0-3.247-.725-3.247-2.424 0-2.424 3.317-2.895 6.031-2.895h1.519-.007Zm-7.905-4.23c1.08-.795 2.129-1.699 4.296-1.699 2.453 0 3.609 1.629 3.609 3.181v.617h-2.091c-4.588 0-8.305 1.336-8.305 5.249 0 2.932 2.421 4.523 5.236 4.523 2.275 0 4.01-.726 5.268-2.678h.07c0 .757.038 1.52.07 2.277h2.567c-.108-.795-.146-1.667-.146-2.71v-7.742c0-2.602-1.843-5.026-6.316-5.026-2.091 0-4.373.725-5.955 2.246l1.697 1.775v-.013Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__y"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__y)">
            <path
              fill="#FEFEFE"
              d="M16.694 30.3a1.924 1.924 0 0 0-1.913 1.916c0 1.05.864 1.914 1.913 1.914s1.951-.865 1.951-1.914c0-1.05-.864-1.915-1.95-1.915Z"
            />
          </g>
          <mask
            id="powered-by-illumina_svg__z"
            width={133}
            height={63}
            x={0}
            y={0}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: "luminance",
            }}
          >
            <path fill="#fff" d="M.02.235V63H133V.235" />
          </mask>
          <g mask="url(#powered-by-illumina_svg__z)">
            <path
              fill="#FEFEFE"
              d="M119.967 37.967h.394v-.98h.324l.578.98h.47l-.616-1.012c.324-.038.578-.216.578-.649 0-.432-.286-.687-.832-.687h-.903v2.354l.007-.006Zm.394-1.992h.324c.286 0 .578 0 .578.325 0 .324-.286.324-.578.324h-.324v-.649Zm-1.697.834c0 1.12.941 2.06 2.053 2.06s2.059-.94 2.059-2.06a2.06 2.06 0 0 0-2.059-2.061 2.03 2.03 0 0 0-2.053 2.06Zm.394 0c0-.942.763-1.699 1.659-1.699s1.696.757 1.696 1.699c0 .941-.762 1.698-1.696 1.698-.934 0-1.659-.757-1.659-1.698Z"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="powered-by-illumina_svg__a">
        <path fill="#fff" d="M0 0h133v63H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPoweredByIllumina;
