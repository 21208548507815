import * as React from "react";
const SvgLvncPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 50"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.153 48.454c10.553-8.106 13.814-28.352-2.355-35.327M17.852 5.699c-.589-.853-1.228-1.358-2.088-1.82a.453.453 0 0 1-.241-.45l.211-1.745a.453.453 0 0 0-.393-.5l-1.495-.18a.455.455 0 0 0-.504.39l-.149 1.236a.45.45 0 0 1-.461.395c-.428-.016-.86 0-1.292.068a.446.446 0 0 1-.491-.324l-.386-1.37a.45.45 0 0 0-.554-.312l-1.453.4a.446.446 0 0 0-.313.55l.482 1.716a.459.459 0 0 1-.182.491c-.322.227-.622.48-.898.753a.457.457 0 0 1-.575.042l-1.487-1.06a.46.46 0 0 0-.631.102l-.88 1.214a.452.452 0 0 0 .1.627L5.85 7.12a.447.447 0 0 1 .16.513 6.626 6.626 0 0 0-.088 3.897c.66 2.363 2.52 4.078 4.739 4.679"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8.107 20.758c3.372.176 5.773-1.707 6.556-8.03C15.76 3.874 26.826 2.227 26.826 2.227a.525.525 0 0 1 .678.294L29.888 8.6a.519.519 0 0 1-.296.672c-3.892 1.535-9.728 3.919-7.513 14.866"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M22.405 42.778c1.394-.765 3.393 1.43 4.168.669.669-.656-1.407-3.486-.881-4.427.465-.841 3.96-.433 4.29-1.421.313-.933-2.715-2.741-2.533-3.746.182-1.005 3.647-1.648 3.68-2.648.034-1-3.383-1.871-3.51-2.846-.136-1.035 2.896-2.826 2.558-3.73-.369-.983-3.748-.21-4.371-.954-.623-.744 4.125-2.88.381-4.893"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M21.164 30.793c0 3.283-9.262 7.823 1.635 18.207C12.051 46.642 3.491 34.673 1.336 27.22c-1.961-6.786 5.484-9.85 1.338-15.825l-1.19-1.172a.18.18 0 0 1 0-.257l3.231-3.56"
    />
  </svg>
);
export default SvgLvncPanel;
