import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Markup } from "interweave";
import { motion, useInView } from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import { useWindowSize } from "../../hooks";
import { BrightPrenatalSeq, Exseq } from "../../assets/svgs";

const ServiceOverview = ({ slides }) => {
  const { windowWidth } = useWindowSize();

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const indicatorWidth = 210 / slides.length;

  const [[page, direction], setPage] = useState([0, 0]);
  const [pause, setPause] = useState(true);
  const index = wrap(0, slides.length, page);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
  };

  let timer;

  useEffect(() => {
    clearTimeout(timer);
    if (!pause) {
      timer = setTimeout(() => {
        paginate(1);
      }, 8000);
    }
  }, [page, pause]);

  useEffect(() => {
    if (isInView) {
      setPause(false);
      setPage([0, 1]);
    }
  }, [isInView]);

  return (
    <Container ref={ref}>
      <Card id="service-overview" color={slides[index].color || "#1AE299"}>
        <Track>
          <Slide
            key={page}
            custom={direction}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 300 },
              opacity: { duration: 1 },
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              clearTimeout(timer);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {slides[index].title === "PrenatalSEQ" ? (
              <BrightPrenatalSeq />
            ) : slides[index].title === "Gene Panels" ? (
              <Exseq />
            ): (
              <h3>{slides[index].title}</h3>
            )}
            <p>
              <Markup content={slides[index].description} />
            </p>
          </Slide>
        </Track>
        <Indicator
          width={indicatorWidth * (index + 1)}
          color={slides[index].color || "#1AE299"}
        />
      </Card>
    </Container>
  );
};

const Container = styled(motion.section)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  padding: 6rem 0 12rem 0;
  @media screen and (min-width: 1024px) {
    padding: 12rem 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Card = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 10px;
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2.375rem;
  width: calc(100% - 2.5rem);
  justify-content: space-between;
  max-width: 1400px;
  transform: translate(-50%, 0);
  padding: 2rem 1.25rem;
  position: absolute;
  top: -120px;
  left: 50%;
  z-index: 5;
  min-height: 460px;
  overflow: hidden;
  h3 {
    color: ${({ color }) => color};
    font-size: 1.875rem;
    font-weight: 400;
  }
  p {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 300;
  }
  @media screen and (min-width: 1024px) {
    top: -90px;
    width: calc(100% - 8rem);
    gap: 5rem;
    min-height: 420px;
    padding: 5rem 3.625rem 2rem 3.625rem;
    h3 {
      font-size: 3rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
`;

const Track = styled(motion.div)`
  cursor: grab;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  position: relative;
  &:active {
    cursor: grabbing;
  }
`;

const Slide = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  svg {
    width: auto;
    height: 100%;
    max-width: 198px;
    max-height: 24px;
    margin-right: auto;
  }
  @media screen and (min-width: 1024px) {
    display: grid;
    gap: 3rem;
    grid-template-columns: 275px 1fr;
    svg {
        max-width: 100%;
        max-height: 36px;
    }
  }
`;

const Indicator = styled.div`
  width: 210px;
  height: 1px;
  background: #e5e5e5;
  position: relative;
  overflow: visible;
  &:before {
    content: "";
    background: ${({ color }) => color};
    width: ${({ width }) => `${width}px`};
    left: 0;
    height: 0.25rem;
    position: absolute;
    top: -1.5px;
    transition: all 0.5s ease-in-out;
  }
`;

export default ServiceOverview;
