import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Input, Label, FormCheck, FormCheckbox, Back } from "../../FormComponents";
import {  useWindowSize } from "../../../../../hooks";
import { ArrowBack } from "../../../../../assets/svgs";
import Recaptcha from "react-google-recaptcha";

const ReviewInformation = ({
  formValues,
  handleInputChange,
  shippingAddress,
  billingAddress,
  setFormPage,
  recaptchaRef,
  setDisabled
}) => {
  const [message, setMessage] = useState("");
  const { windowWidth } = useWindowSize();;

  return (
    <Fields>
      <Back
      type="button"
        onClick={() => {
          setFormPage(3);
        }}
      >
        <ArrowBack />
      </Back>
      <Title>Basic Information{windowWidth > 780 && <Edit onClick={() => setFormPage(1)}>Edit</Edit>}</Title>
      <Details>
        <p>
          <span>Full Name:</span> {formValues.name}
        </p>
        <p>
          <span>Institution:</span> {formValues.institution}
        </p>
        <p>
          <span>Department:</span> {formValues.department}
        </p>
        <p>
          <span>Email:</span> {formValues.email}
        </p>
        {formValues.phoneNumber && (
          <p>
            <span>Phone Number:</span> {formValues.phoneNumber}
          </p>
        )}
        <br />
      </Details>
      <Title>Shipping Address{windowWidth > 720 && <Edit onClick={() => setFormPage(2)}>Edit</Edit>}</Title>
      <p hidden>
        <Label>
          Don’t fill this out:
          <Input
            name="shippingAddress"
            value={shippingAddress}
          />
        </Label>
      </p>
      <Details>
        <p>
          {shippingAddress.address1}
          <br />
          {shippingAddress.address2 && (
            <>
              {shippingAddress.address2}
              <br />
            </>
          )}
          {shippingAddress.address3 && (
            <>
              {shippingAddress.address3}
              <br />
            </>
          )}
          {shippingAddress.county}
          <br />
          {shippingAddress.postcode}
          <br />
          {shippingAddress.country}
          <br />
          <br />
        </p>
      </Details>
      <Title>Billing Address{windowWidth > 720 && <Edit onClick={() => setFormPage(3)}>Edit</Edit>}</Title>
      <p hidden>
        <Label>
          Don’t fill this out:
          <Input
            name="billingAddress"
            value={billingAddress}
          />
        </Label>
      </p>
      <Details>
        <p>
          {billingAddress.address1}
          <br />
          {billingAddress.address2 && (
            <>
              {billingAddress.address2}
              <br />
            </>
          )}
          {billingAddress.address3 && (
            <>
              {billingAddress.address3}
              <br />
            </>
          )}
          {billingAddress.county}
          <br />
          {billingAddress.postcode}
          <br />
          {billingAddress.country}
          <br />
          <br />
        </p>
      </Details>
      <Title>Billing Details{windowWidth > 720 && <Edit onClick={() => setFormPage(3)}>Edit</Edit>}</Title>
      <Details>
        <p>
          <span>Finance Contact:</span> {formValues.financeContact}
        </p>
        <p>
          <span>Finance Contact Email:</span> {formValues.financeContactEmail}
        </p>
        <p>
          <span>Finance Contact Phone Number:</span>{" "}
          {formValues.financeContactPhoneNumber}
        </p>
        <p>
          <span>Does your institution require a PO<br /> to process payments?</span>{" "}
          {formValues.requirePoForPayments ? "Yes" : "No"}
        </p>
        {formValues.taxableStatus && (
          <p>
            <span>Taxable Status:</span> {formValues.taxableStatus}
          </p>
        )}
        <p>
          <span>VAT/Tax ID Number:</span> {formValues.vatTaxIdNumber}
        </p>
      </Details>
      <Label>
        <FormCheck>
          <FormCheckbox
            type="checkbox"
            id="confirmHCP"
            name="confirmHCP"
            onChange={(e) => {
              handleInputChange(e);
            }}
            checked={formValues.confirmHCP}
            value={formValues.confirmHCP}
            required
          />
          <p>
            I confirm that I am a qualified healthcare professional.
            <span>*</span>
          </p>
        </FormCheck>
      </Label>
      <Label>
        <FormCheck small>
          <FormCheckbox
            type="checkbox"
            id="acceptTermsAndConditions"
            name="acceptTermsAndConditions"
            onChange={(e) => {
              handleInputChange(e);
            }}
            checked={formValues.acceptTermsAndConditions}
            value={formValues.acceptTermsAndConditions}
          />
          <p>
            I accept the general terms and conditions for Genseq's Clinical
            Genetic Testing Services and the terms of use of the clinical
            portal.<span>*</span>
          </p>
        </FormCheck>
      </Label>
      <RecaptchaContainer>
        <Recaptcha
          ref={recaptchaRef}
          sitekey="6Lch700pAAAAALDw2GocqmC-3fbqhqIPeJY2lkEo"
          size="normal"
          id="recaptcha-google"
          onExpired={() => {
            setDisabled(true);
            recaptchaRef.execute();
          }}
          onChange={() => {
            if (
              formValues.confirmHCP &&
              formValues.acceptTermsAndConditions &&
              recaptchaRef.current.getValue()
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }}
        />
      </RecaptchaContainer>
    </Fields>
  );
};

const Fields = styled.div`
  display: flex;
  flex-direction: column;
`;

const Print = styled(motion.p)`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 1.25rem 0;
`;

const Title = styled.h5`
  align-items: center;
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Details = styled(motion.div)`
  display: flex;
  flex-direction: column;
  p {
    align-items: center;
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    span {
      color: var(--genseq-orange);
      font-weight: 500;
    }
  }
`;

const RecaptchaContainer = styled.div`
  margin-top: 1.25rem;
  @media screen and (min-width: 780px) {
    margin-left: -0.5rem;
  }
`;

const Edit = styled.button`
  color: var(--genseq-blue);
  cursor: pointer;
  font-size: 0.875rem;
  text-decoration: underline;
`;

export default ReviewInformation;
