import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Close, ChevronDown } from "../../../../assets/svgs";

const PatientCardExpanded = ({
  cardExpanded,
  onDismiss,
  children,
  selectedCard,
  setSelectedCard,
}) => {
  const [direction, setDirection] = useState(0);
  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset, velocity) => Math.abs(offset) * velocity;
  return (
    <div style={{ position: "relative" }}>
      <LeftArrow
        type="button"
        color={cardExpanded.color}
        onClick={(e) => {
          e.preventDefault();
          setDirection("-25px");
          if (selectedCard === 0) {
            setSelectedCard(2);
          } else {
            setSelectedCard(selectedCard - 1);
          }
        }}
      >
        <ChevronDown />
      </LeftArrow>
      <Container
        key={selectedCard}
        initial={{ opacity: 0, left: direction }}
        animate={{ opacity: 1, left: 0 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 2,
          ease: [0.04, 0.62, 0.23, 0.98],
          opacity: { duration: 0.5 },
        }}
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        dragElastic={1}
        onDragEnd={(e, { offset, velocity }) => {
          const swipe = swipePower(offset.x, velocity.x);

          if (swipe < -swipeConfidenceThreshold) {
            setDirection("25px");
            if (selectedCard === 2) {
              setSelectedCard(0);
            } else {
              setSelectedCard(selectedCard + 1);
            }
          } else if (swipe > swipeConfidenceThreshold) {
            if (selectedCard === 0) {
              setSelectedCard(2);
            } else {
              setSelectedCard(selectedCard - 1);
            }
          }
        }}
      >
        <CloseButton onClick={onDismiss}>
          <Close />
        </CloseButton>
        <GatsbyImage
          image={getImage(cardExpanded.thumbnailImage)}
          alt={cardExpanded.thumbnailAlt}
        />
        <Content>
          <Title color={cardExpanded.color}>{cardExpanded.title}</Title>
          {children}
        </Content>
      </Container>
      <RightArrow
        type="button"
        color={cardExpanded.color}
        onClick={(e) => {
          e.preventDefault();
          setDirection("25px");
          if (selectedCard === 2) {
            setSelectedCard(0);
          } else {
            setSelectedCard(selectedCard + 1);
          }
        }}
      >
        <ChevronDown />
      </RightArrow>
    </div>
  );
};

const Container = styled(motion.div)`
  background: #f8f8f8;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 200px auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  max-width: 960px;
  @media screen and (min-width: 1024px) {
    grid-template-rows: 340px auto;
    min-width: 960px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.25rem;
  @media screen and (min-width: 1024px) {
    padding: 3rem 3rem 6rem 3rem;
  }
`;

const Title = styled.h3`
  color: ${({ color }) => color};
  font-size: 2.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
  @media screen and (min-width: 780px) {
    margin-bottom: 3rem;
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  height: 12px;
  width: 12px;
  z-index: 10;
  svg {
    height: 100%;
    width: auto;
  }
`;

const LeftArrow = styled.button`
  cursor: pointer;
  height: 40px;
  left: -4rem;
  position: absolute;
  top: 500px;
  width: 40px;
  z-index: 10;
  svg {
    height: 30px;
    transform: rotate(90deg);
    width: auto;
    path {
      stroke: ${({ color }) => color};
    }
  }
`;

const RightArrow = styled.button`
  cursor: pointer;
  height: 40px;
  position: absolute;
  right: -4rem;
  top: 500px;
  width: 40px;
  z-index: 10;
  svg {
    height: 30px;
    transform: rotate(-90deg);
    width: auto;
    path {
      stroke: ${({ color }) => color};
    }
  }
`;

export default PatientCardExpanded;
