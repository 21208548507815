import * as React from "react";
const SvgConsentForm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 38 56"
    {...props}
  >
    <g clipPath="url(#consent-form_svg__a)">
      <path
        stroke="#061C57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M28.552 8.018V4.384H22.51a3.628 3.628 0 1 0-7.255 0H9.212v3.634M30.972 8.018h6.043v47.228H.752V8.018h25.384"
      />
      <ellipse cx={32.18} cy={40.714} fill="#061C57" rx={0.753} ry={0.754} />
      <path
        stroke="#061C57"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M32.18 44.348v6.054H5.588v-37.54H32.18v24.221"
      />
      <path
        stroke="#36CBFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M18.882 23.76h9.67M18.882 20.13h9.67M18.882 29.815h9.67M18.882 33.45h9.67M10.423 21.34l2.416 2.42 3.627-3.63M15.258 29.815h-4.835v4.844h4.835v-4.844ZM18.882 39.503h9.67M18.882 43.138h4.835M15.258 39.503h-4.835v4.845h4.835v-4.845Z"
      />
      <ellipse cx={18.882} cy={4.388} fill="#061C57" rx={0.753} ry={0.754} />
    </g>
    <defs>
      <clipPath id="consent-form_svg__a">
        <path fill="#fff" d="M0 0h37.767v56H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgConsentForm;
