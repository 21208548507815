import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { serviceIconMapper } from "../../../../utils";
import { Exseq } from "../../../../assets/svgs";
import { Link } from "gatsby";

const ClinicalCategoryServicesMenu = ({
  category,
  services,
  setDropdownOpen,
  setShowServicesMenu,
}) => {
  const categoryServices = services.edges.filter(
    ({ node }) => node.serviceCategory.title === category.title
  );
  const categorySubcategories = [];
  const categoryServicesWithoutSubcategories = [];
  categoryServices.forEach(({ node }) => {
    if (
      node.serviceSubCategory &&
      categorySubcategories.findIndex(
        (category) => category.title === node.serviceSubCategory.title
      ) === -1
    ) {
      categorySubcategories.push(node.serviceSubCategory);
    } else if (!node.serviceSubCategory) {
      categoryServicesWithoutSubcategories.push(node);
    }
  });

  const [colors, setColors] = useState([
    "#645FE6",
    "#1AE299",
    "#FF7D50",
    "#36CBFF",
  ]);

  const colorsRequired =
    categorySubcategories.length + categoryServicesWithoutSubcategories.length;

  useEffect(() => {
    if (colorsRequired > 4) {
      let newColors = [];
      const amount = Math.ceil(colorsRequired / 4);
      for (let index = 0; index < amount; index++) {
        newColors.push(...["#645FE6", "#1AE299", "#FF7D50", "#36CBFF"]);
      }
      setColors(newColors);
    }
  }, [services]);

  return (
    <>
      <span>
        <Link
          to={`/services/clinical/${category.slug.current}`}
          onClick={() => {
            setDropdownOpen(false);
            setShowServicesMenu(false);
          }}
        >
          {category.title}
        </Link>
      </span>
      <div>
        {category.slug.current === "gene-panels" && (
          <div>
            <MenuItemExpanded
              to={`/services/clinical/${category.slug.current}`}
              onClick={() => {
                setDropdownOpen(false);
                setShowServicesMenu(false);
              }}
            >
              <Exseq width={57} />
              <p>Gene Panel Testing</p>
            </MenuItemExpanded>
          </div>
        )}
        {categorySubcategories.map((subcategory, index) => {
          const SVG = serviceIconMapper[subcategory.slug.current];
          return (
            <MenuItem
              to={`/services/clinical/${category.slug.current}/${subcategory.slug.current}`}
              onClick={() => {
                setDropdownOpen(false);
                setShowServicesMenu(false);
              }}
            >
              <SVGContainer>
                {SVG ? <SVG color={colors[index]} /> : <div />}
              </SVGContainer>
              <p>{subcategory.title}</p>
            </MenuItem>
          );
        })}
        {categoryServicesWithoutSubcategories.map((service, index) => {
          const SVG = serviceIconMapper[service.slug.current];
          return (
            <MenuItem
              to={`/services/clinical/${category.slug.current}/${service.slug.current}`}
              onClick={() => {
                setDropdownOpen(false);
                setShowServicesMenu(false);
              }}
            >
              <SVGContainer>
                {SVG ? (
                  <SVG color={colors[index + categorySubcategories.length]} />
                ) : (
                  <div />
                )}
              </SVGContainer>
              <p>{service.title}</p>
            </MenuItem>
          );
        })}
      </div>
      <br />
    </>
  );
};

const MenuItem = styled(Link)`
  display: grid;
  cursor: pointer;
  grid-template-rows: 40px 1fr;
  font-size: 1.25rem;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  > div {
    background: white;
    height: 50px;
    width: 50px;
    padding: 0.5rem;
    margin: 0 auto;
  }
  p {
    color: var(--genseq-blue);
    font-size: 1rem;
    font-weight: 700;
    transition: all 0.3s ease-out;
  }
  svg {
    width: 24px;
    height: auto;
  }
  @media screen and (min-width: 1024px) {
    background: transparent;
    align-items: start;
    grid-template-rows: none;
    grid-template-columns: 24px 1fr;
    justify-content: start;
    text-align: left;
    > div {
      background: transparent;
      border-radius: 0;
      height: unset;
      width: unset;
      padding: 0;
      margin: 0;
    }
  }
`;

const SVGContainer = styled.div`
  width: 24px;
  height: auto;
  margin: 0 auto;
  svg {
    width: 24px;
    height: auto;
    max-height: 24px;
  }
  @media screen and (min-width: 780px) {
    margin: unset;
  }
`;

const LogoContainer = styled.div`
  height: 13px;
  width: 100%;
  svg {
    height: 100%;
    width: auto;
  }
`;

const MenuItemExpanded = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  gap: 0.5rem;
  p {
    font-weight: 300;
    color: var(--genseq-blue);
  }
`;

export default ClinicalCategoryServicesMenu;
