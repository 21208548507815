import React from "react";
import styled from "styled-components";
import { Markup } from "interweave";

const PrenatalOverview = ({ service }) => {
  const {
    title,
    testName,
    testCode,
    serviceCategory,
    turnaroundTime,
    whoTestFor,
    whatIsTested,
    testApproach,
    slug,
  } = service;
  return (
    <Container>
      <h5>Screen Name</h5>
      <Text>{testName}</Text>
      <h5>Screen Code</h5>
      <Text>{testCode}</Text>
      <h5>Screen Category</h5>
      <Text>{serviceCategory}</Text>
      <h5>Who Is The Screen For?</h5>
      <Text>{whoTestFor}</Text>
      <h5>Screen Approach</h5>
      <Text>{testApproach}</Text>
      <h5>What Is Screened?</h5>
      <Text>
        <Markup content={whatIsTested} />
      </Text>
      <h5>Deliverables</h5>
      <Text>
        <p>Clinical Report</p>
      </Text>
      <h5>Turnaround Time</h5>
      <Text>
        <Markup content={turnaroundTime} />
      </Text>
      <h5>Specimen Requirements</h5>
      <Text>
        <p>
          For detailed information about the sample requirements, please consult our{" "}
          <a href="/services/clinical/resources/sample-requirements">
            clinical sample requirements page.
          </a>
        </p>
      </Text>
      <SmallText>
        *Turnaround times are estimated from receipt of satisfactory specimen
        and test request form at the laboratory to release of clinical report.
        The Turnaround time may vary depending on the nature of the specimen and
        the complexity of the investigation required. The above table is only a
        guideline and the complexity of a case and the requirement for further
        investigations may change this.
      </SmallText>
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 0.25rem;
  display: grid;
  grid-template-columns: 1fr 2fr;
  position: relative;
  margin-bottom: 4rem;
  h5 {
    font-size: 1rem;
    padding: 1rem 1.25rem;
    &:not(:last-of-type) {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  @media screen and (min-width: 1024px) {
    margin-left: -1.25rem;
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  border-left: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  a {
    color: var(--genseq-purple);
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  p {
    margin-bottom: 1.25rem;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const SmallText = styled.span`
  position: absolute;
  left: 0;
  bottom: -7rem;
  font-size: 0.75rem;
  @media screen and (min-width: 1024px) {
    left: 1.25rem;
    bottom: -3.5rem;
  }
`;

export default PrenatalOverview;
