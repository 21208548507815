import React, { useRef } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion, useScroll, useTransform, cubicBezier } from "framer-motion";
import { Markup } from "interweave";
import { useWindowSize } from "../../../hooks";
import { GridBackground } from "../../../assets/svgs";

const BiobankingServiceIntroduction = ({
  biobanking,
  sampleManagement,
  serviceDetails,
}) => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativeDirectory: { eq: "assets/images/biobanking" } }
          sort: { name: ASC }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  );
  const { windowWidth } = useWindowSize();
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["end end", "start start"],
  });

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [-distance, distance], {
      ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
    });
  };

  const topY = useParallax(scrollYProgress, 25);
  const middleY = useParallax(scrollYProgress, -15);
  const bottomY = useParallax(scrollYProgress, 25);

  return (
    <Container id="biobanking-services">
      <Introduction>
        <div>
          <h2>Biobanking</h2>
          <Markup content={biobanking} />
        </div>
        <div />
        <div>
          <h2>Sample Management</h2>
          <Markup content={sampleManagement} />
        </div>
        {windowWidth >= 1024 && (
          <Images ref={containerRef}>
            <TopImage style={{ y: topY }}>
              <GatsbyImage
                image={getImage(images.edges[0].node.childImageSharp)}
                alt={images.edges[0].node.name}
              />
            </TopImage>
            <MiddleImage style={{ y: middleY }}>
              <GatsbyImage
                image={getImage(images.edges[1].node.childImageSharp)}
                alt={images.edges[1].node.name}
              />
            </MiddleImage>
            <SVGBackground>
              <GridBackground />
            </SVGBackground>
            <BottomImage style={{ y: bottomY }}>
              <GatsbyImage
                image={getImage(images.edges[2].node.childImageSharp)}
                alt={images.edges[2].node.name}
              />
            </BottomImage>
          </Images>
        )}
      </Introduction>
      <Details>
        <h3>Service Details</h3>
        <Table>
          <Markup content={serviceDetails} />
        </Table>
      </Details>
      {windowWidth < 1024 && (
        <Images ref={containerRef}>
          <TopImage style={{ y: topY }}>
            <GatsbyImage
              image={getImage(images.edges[0].node.childImageSharp)}
              alt={images.edges[0].node.name}
            />
          </TopImage>
          <MiddleImage style={{ y: middleY }}>
            <GatsbyImage
              image={getImage(images.edges[1].node.childImageSharp)}
              alt={images.edges[1].node.name}
            />
          </MiddleImage>
          <SVGBackground>
            <GridBackground />
          </SVGBackground>
          <BottomImage style={{ y: bottomY }}>
            <GatsbyImage
              image={getImage(images.edges[2].node.childImageSharp)}
              alt={images.edges[2].node.name}
            />
          </BottomImage>
        </Images>
      )}
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 7;
  margin-top: 3rem;
  gap: 3rem;
  padding: 0 1.25rem;
  @media screen and (min-width: 1024px) {
    grid-column: 2 / 13;
    margin-top: 6rem;
    padding: unset;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 13;
  }
`;

const Introduction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  h2 {
    color: var(--genseq-purple);
    font-size: 2.25rem;
    font-weight: 400;
    padding-bottom: 1.75rem;
  }
  @media screen and (min-width: 1024px) {
    gap: 3rem 6rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const Images = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  right: 0;
  img {
    box-shadow: 0px 12px 30px rgba(217, 217, 217, 0.7);
    height: auto;
    width: 100%;
  }
`;

const TopImage = styled(motion.div)`
  grid-column: 4 / 7;
  grid-row-start: 0;
  grid-row-end: 1;
  z-index: 2;
  @media screen and (min-width: 1024px) {
    padding: 0;
    grid-column: 3 / 7;
  }
`;

const MiddleImage = styled(motion.div)`
  grid-column: 2 / 5;
  grid-row-start: 0;
  grid-row-end: 1;
  margin-top: auto;
  margin-bottom: 3rem;
  margin-left: -3rem;
  padding-right: 3.125rem;
  z-index: 2;
  @media screen and (min-width: 1024px) {
    grid-column: 1 / 4;
    margin-bottom: -3rem;
    margin-right: -3rem;
    padding-right: 3.125rem;
  }
`;

const BottomImage = styled(motion.div)`
  grid-column: 3 / 7;
  padding: 0.75rem 4rem;
  position: relative;
  top: -2rem;
  right: -1rem;
  z-index: 0;
  @media screen and (min-width: 1024px) {
    right: unset;
    left: 4rem;
    top: 0;
    grid-column: 3 / 6;
    padding: 0.75rem 3rem;
  }
`;

const SVGBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  svg {
    height: 50%;
    width: auto;
    right: 0;
    top: 50%;
    transform: translate(0, 50%);
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    margin-top: -6rem;
  }
`;

const Table = styled.div`
  background: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #e5e5e5;
  max-width: 740px;
  width: 100%;
  span {
    display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-row: 1fr 1fr;
  }
  p {
    border-left: 1px solid #e5e5e5;
    padding: 1rem;
    &:first-of-type {
      border-bottom: 1px solid #e5e5e5;
    }
  }
  strong {
    padding: 1rem;
    &:first-of-type {
      border-bottom: 1px solid #e5e5e5;
    }
  }
`;

export default BiobankingServiceIntroduction;
