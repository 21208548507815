import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { Button } from "../../../components";

const HCPOnlyChallenge = ({ closeChallengeDialog }) => {
    return (
        <Challenge>
          <p>
            The documents on this page are intended for healthcare
            professionals.
            <br />
            Before entering, please confirm you are a qualified healthcare
            professional by clicking continue.
          </p>
          <div>
            <Button
              type="button"
              solid="var(--genseq-purple)"
              text="Continue"
              onClick={(e) => {
                e.preventDefault();
                closeChallengeDialog();
              }}
            />
            <Button
              type="button"
              solid="var(--genseq-green)"
              text="Go Back"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            />
          </div>
        </Challenge>
    )
};


const Challenge = styled.div`
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-size: 1.25rem;
  text-align: center;
  padding: 3rem 1.25rem 1.5rem 1.25rem;
  width: 100%;
  position: relative;
  > div {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    gap: 1.5rem;
  }
  p {
    max-width: 700px;
    margin: 0 auto;
  }
  @media screen and (min-width: 1024px) {
    min-width: 960px;
    padding: 3rem 1.25rem;
  }
`;

export default HCPOnlyChallenge;