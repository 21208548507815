import * as React from "react";
const SvgClinicianSupport = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 50"
    {...props}
  >
    <path
      stroke="#061C57"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M26.943 49v-3.816h13.053V49m-10.743-4.094c-.492-4.254.119-6.667 2.011-8.538 1.595-1.565 2.623-2.208 4.888-2.647a431.911 431.911 0 0 1 2.92-2.85c.418-.41 1.863-1.492 3.77-.147a.806.806 0 0 1 .09 1.228l-5.499 5.395m4.366-7.559s.566-3.962.954-6.652c.343-2.34 1.639-3.26 3.248-3.494.537-.088 1.014.35.999.891l-.403 13.364c0 .395-.178.775-.462 1.052l-9.328 9.153M21.057 49v-3.816H8.004V49m18.954 0v-3.816H40.01V49m-21.264-4.094c.492-4.254-.119-6.667-2.011-8.538-1.595-1.565-2.623-2.208-4.903-2.647a98944.23 98944.23 0 0 0-2.905-2.85c-.418-.41-1.863-1.492-3.77-.147a.806.806 0 0 0-.09 1.228l5.499 5.395M6.2 29.788s-.566-3.962-.954-6.652c-.343-2.34-1.639-3.26-3.248-3.494-.537-.088-1.014.35-.999.891l.403 13.364c0 .395.178.775.462 1.052l9.328 9.153"
    />
    <path
      stroke="#FF7D50"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m26.303 36.236.775-10.278s.09 0 .119-.015c.954-.132 2.354-.629 2.444-1.74l.536-7.09v-.118c0-1.418-1.37-2.588-2.757-2.632h-6.69c-1.4.03-2.533 1.214-2.533 2.617l.536 7.223c.075 1.111 1.505 1.608 2.459 1.74.044 0 .09 0 .119.015l.775 10.307m4.187 0s.015 0 .015-.014m7.763-6.784.67-8.904s.09 0 .12-.015c.954-.132 2.355-.629 2.444-1.74l.536-7.091V11.6c0-1.418-1.37-2.588-2.756-2.617h-4.947m-12.13 0h-4.947c-1.4.03-2.533 1.213-2.533 2.617l.536 7.223c.075 1.11 1.505 1.608 2.459 1.74.045 0 .09 0 .12.014l.67 8.904m9.879-17.925c1.46 0 2.637-1.17 2.637-2.588 0-1.418-1.192-2.588-2.637-2.588s-2.637 1.17-2.637 2.588c0 1.418 1.192 2.588 2.637 2.588Zm-5.99 5.425.536 7.222c.075 1.111 1.505 1.608 2.459 1.74.045 0 .09 0 .12.015l.774 10.307h4.187s.015 0 .015-.014l.775-10.279s.09 0 .12-.014c.953-.132 2.354-.63 2.443-1.74l.536-7.091v-.117c0-1.419-1.37-2.588-2.756-2.632H20.7c-1.402.03-2.534 1.213-2.534 2.617l.015-.015ZM31.995 6.19c1.46 0 2.637-1.17 2.637-2.589C34.633 2.184 33.44 1 31.996 1s-2.637 1.17-2.637 2.602c0 1.433 1.192 2.588 2.637 2.588Zm-15.333 0c1.46 0 2.637-1.17 2.637-2.589C19.3 2.184 18.107 1 16.662 1s-2.638 1.17-2.638 2.602c0 1.433 1.193 2.588 2.638 2.588Z"
    />
  </svg>
);
export default SvgClinicianSupport;
