import * as React from "react";
const SvgHereditaryBreastCancerPanel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 46 52"
    {...props}
  >
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M31.015 41.233c-.25.622-.657 5.095 3.286 9.767M39.343 28.577h2.294c.527 0 1.707-1.399 1.458-1.864-.343-.64-.635-1.251-.581-1.433.08-.284.545-.364 1.638-1.015 1.093-.646.97-1.542.526-2.23-.443-.69-3.401-4.543-3.477-5.718-.05-.759.62-2.917-.04-5.92-.49-2.239-1.84-4.722-4.138-5.994M39.246 35.917c1.843-.713 1.656-2.308 1.656-3.241 0-1.26 1.735-2.172.815-4.043"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M34.297 34.809c2.504.87 3.3 1.822 4.95 1.108"
    />
    <path
      stroke={props.invert ? '#FFFFFF' : '#061C57'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.448 31.658C5.457 18.51 9.775 1 26.95 1c1.934 0 7.991.56 9.824 2.336 0 3.56-4.337 3.13-3.204 8.568 3.922 18.827-1.169 36.005-9.556 30.48-1.45-.955-3.11-.892-4.823-.466"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13.013 41.08 1.268-1.536c2.843-3.4.4-8.544-4.064-8.544s-6.907 5.143-4.063 8.544L15.719 51 19 48.139 8.968 36.309c-1.231-1.472-1.472-3.266-.986-4.828"
    />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.22 44.4-5.876 6.233L1 47.321l6.514-6.152M8.008 34.573a2.171 2.171 0 0 1 2.706.272 2.112 2.112 0 0 1 0 3.011"
    />
  </svg>
);
export default SvgHereditaryBreastCancerPanel;
