import React from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { MainVideo } from "../../../components";
import { AnimatePresence, motion } from "framer-motion";
import ReactPlayer from "react-player/lazy";

const BiopharmaServiceIntroduction = ({ services }) => {
  return (
    <Container id="biopharma-services">
      <Title>Biopharma / Research Services</Title>
      <Menu menuItems={services} />
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 7;
  padding: 0 1.25rem;
  @media screen and (min-width: 1024px) {
    grid-column: 2 / 12;
    padding: unset;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3 / 11;
  }
`;

const Title = styled.h2`
  color: var(--genseq-purple);
  font-size: 2.25rem;
  font-weight: 400;
  padding-bottom: 3rem;
`;

const WideWrapper = styled(motion.div)`
  margin: -1rem 0 3rem 0;
  border-radius: 0.625rem;
  overflow: hidden;
  @media screen and (min-width: 1024px) {
    width: calc(100% + 8rem);
    margin: -1rem 0 6rem -4rem;
  }
`;

const Video = styled(motion.div)`
  border-radius: 0.625rem;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
  position: relative;
  overflow: hidden;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Overlay = styled(motion.div)`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  div {
    position: relative;
    height: 100%;
    width: 100%;
    svg {
      height: 62px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 62px;
    }
  }
  @media screen and (min-width: 1024px) {
    div {
      svg {
        height: 128px;
        width: 128px;
      }
    }
  }
`;

export default BiopharmaServiceIntroduction;
