import * as React from "react";
const SvgChevronDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 10 7"
    {...props}
  >
    <path stroke="#fff" strokeWidth={2} d="M.758 1.243 5 5.485l4.243-4.242" />
  </svg>
);
export default SvgChevronDown;
